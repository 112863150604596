import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setMembers } from "../../../../../redux/restaurant";

import { Add } from "@mui/icons-material";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Button,
  Modal,
  ModalClose,
  Typography,
  Sheet,
} from "@mui/joy";

import { DOMAIN } from "../../../../../utils/config";
import { getAuthorizationHeader } from "../../../../../utils/helpers";
import { setNotify } from "../../../../../redux/utils";

import axios from "axios";

export default function AddMember() {
  const { restaurant, members } = useSelector((state) => state.restaurant);

  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState("");

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    setSaving(true);
    axios({
      method: "POST",
      url: `${DOMAIN}/organizations/members/`,
      headers: getAuthorizationHeader(),
      params: {
        organization_uid: restaurant.uid,
      },
      data: {
        email,
      },
    })
      .then((res) => {
        setError("");
        setSaving(false);
        setEmail("");
        dispatch(setMembers([...members, res.data]));
        dispatch(
          setNotify({
            open: true,
            action: "member Added!",
            severity: "success",
            autoHideDuration: 5000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
        setOpen(false);
      })
      .catch((err) => {
        setSaving(false);
        let errorMsg = "No user found with this email";
        try {
          errorMsg = JSON.parse(err.request.response).message;
        } catch (error) {}
        setError(errorMsg);
      });
  };

  return (
    <>
      <Button
        variant="outlined"
        size="sm"
        startDecorator={<Add fontSize="small" />}
        color="neutral"
        onClick={() => setOpen(true)}
      >
        Add Member
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 500,
            minWidth: 400,
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Stack spacing={1}>
            <Typography
              component="h2"
              id="modal-title"
              level="h4"
              fontWeight="lg"
            >
              Add a Team Member
            </Typography>
            <Typography level="body-xs">
              By default, all newly added Team Members are assigned the{" "}
              <b>Low Level Access</b> to your Restaurant.
            </Typography>
            <form onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <FormControl error={Boolean(error)}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    required
                    size="sm"
                    type="email"
                    value={email}
                    placeholder="john.doe@restaurant.com"
                    onChange={(e) => {
                      setError("");
                      setEmail(e.target.value);
                    }}
                  />
                  <FormHelperText>{error}</FormHelperText>
                </FormControl>
                <Button
                  color="neutral"
                  startDecorator={<Add fontSize="small" />}
                  size="sm"
                  type="submit"
                  disabled={saving}
                >
                  Add Member
                </Button>
              </Stack>
            </form>
          </Stack>
        </Sheet>
      </Modal>
    </>
  );
}

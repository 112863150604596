import { Circle } from "@mui/icons-material";
import { Stack, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCurrency } from "../../../../../../../utils/config";

export default function TableOrderItem({ order, orderItem }) {
  const { restaurant, items, variants } = useSelector((state) => state.restaurant);

  const [loaded, setLoaded] = useState(false);
  const [itemVariant, setItemVariant] = useState({});
  const [item, setItem] = useState({});

  useEffect(() => {
    const itemVariant = variants.filter(
      (i) => i.id === orderItem.item_variant
    )[0];
    setItemVariant(itemVariant);

    const item = items.filter((i) => i.id === itemVariant.item)[0];
    setItem(item);

    setLoaded(true);
  }, [items, orderItem.item_variant, variants]);

  return loaded ? (
    <Stack direction="row" justifyContent="space-between">
      <Stack spacing={0}>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Circle
            fontSize="small"
            sx={{ color: item.is_veg ? "#00B620" : "#F90202" }}
          />
          <Typography level="body-md">{item.name}</Typography>
        </Stack>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Typography level="body-sm" textColor="neutral.400" fontWeight={700}>
            {itemVariant.name}
          </Typography>
          <Typography level="body-sm" textColor="neutral.400">
            ({getCurrency(restaurant.currency)}
            {itemVariant.price})
          </Typography>
        </Stack>
      </Stack>
      <Typography>x {orderItem.qty}</Typography>
    </Stack>
  ) : null;
}

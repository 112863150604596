import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setVariants } from "../../../../../redux/restaurant";

import { Stack, Input, Grid, IconButton } from "@mui/joy";

import {
  Menu as MenuIcon,
  Check,
  Close,
} from "@mui/icons-material";

import axios from "axios";

import { getAuthorizationHeader } from "../../../../../utils/helpers";
import { DOMAIN, getCurrencyIcon } from "../../../../../utils/config";
import { setNotify } from "../../../../../redux/utils";

export default function AddVariant({ item, setAddVariant }) {
  const { restaurant, variants } = useSelector((state) => state.restaurant);

  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [saving, setSaving] = useState(false);

  const dispatch = useDispatch();

  const handleSave = () => {
    setSaving(true);
    axios({
      method: "POST",
      url: `${DOMAIN}/menus/item-variants/`,
      headers: getAuthorizationHeader(),
      params: {
        uid: restaurant.uid,
        item_id: item.id,
      },
      data: {
        name,
        price,
      },
    })
      .then((res) => {
        setSaving(false);
        dispatch(setVariants([...variants, res.data]));
        setName("");
        setPrice("");
        setAddVariant(false);

        dispatch(
          setNotify({
            open: true,
            action: "Variant Added!",
            severity: "success",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      })
      .catch((err) => {
        setSaving(false);
        let errorMessage = "Something went Wrong!";
        try {
          errorMessage = JSON.parse(err.request.response).message;
        } catch (error) {}
        dispatch(
          setNotify({
            open: true,
            action: errorMessage,
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      });
  };

  return (
    <Grid container>
      <Grid xs={1}>
        <IconButton size="sm" disabled>
          <MenuIcon fontSize="small" />
        </IconButton>
      </Grid>
      <Grid xs={5}>
        <Stack direction="row" alignItems="flex-end" spacing={1}>
          <Input
            fullWidth
            required
            size="sm"
            type="text"
            placeholder="eg. Large"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Stack>
      </Grid>
      <Grid xs={6} paddingLeft={2}>
        <Stack direction="row" alignItems="flex-end" spacing={2}>
          <Input
            fullWidth
            required
            size="sm"
            type="number"
            placeholder="Enter Price"
            startDecorator={getCurrencyIcon(restaurant.currency)}
            value={price}
            onChange={(e) => {
              setPrice(e.target.value);
            }}
          />
          <Stack direction="row" spacing={0.4}>
            <IconButton
              color="neutral"
              size="sm"
              disabled={saving}
              onClick={handleSave}
            >
              <Check />
            </IconButton>
            <IconButton
              color="danger"
              size="sm"
              onClick={() => setAddVariant(false)}
            >
              <Close />
            </IconButton>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
}

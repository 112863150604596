import { Button, Sheet, Stack, Typography } from "@mui/joy";
import { Link } from "react-router-dom";

import urls from "../../../utils/urls.json";
import { PersonOutline } from "@mui/icons-material";
import { useSelector } from "react-redux";

export default function Header() {
  const { user } = useSelector((state) => state.auth);

  return (
    <Sheet
      sx={{
        bgcolor: "#fff",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        py={2}
        px={2}
        boxShadow="sm"
      >
        <Stack direction="row" spacing={0.5}>
          <Typography level="h3" component="h1" fontWeight={500}>
            Smart
          </Typography>
          <Typography level="h3" component="h1" fontWeight={700}>
            Dine-in
          </Typography>
        </Stack>
        <Stack direction="row" spacing={3} alignItems="center">
          <Button
            variant="plain"
            color="neutral"
            startDecorator={<PersonOutline />}
            component={Link}
            to={urls.account.children.account.absolutePath}
          >
            {user.first_name && user.last_name
              ? `${user.first_name} ${user.last_name}`
              : "Profile"}
          </Button>
        </Stack>
      </Stack>
    </Sheet>
  );
}

import { Circle } from "@mui/icons-material";
import { Stack, Typography } from "@mui/joy";
import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { getCurrency } from "../../../../../../utils/config";

export default function OrderItem({ orderItem }) {
  const { restaurant, variants, items } = useSelector(
    (state) => state.restaurant
  );

  const [loaded, setLoaded] = useState(false);
  const [item, setItem] = useState({});
  const [variant, setVariant] = useState({});

  useEffect(() => {
    const variant = variants.filter((i) => i.id === orderItem.item_variant)[0];
    setVariant(variant);

    const item = items.filter((i) => i.id === variant.item)[0];
    setItem(item);
    setLoaded(true);
  }, [items, orderItem.item_variant, variants]);

  return loaded ? (
    <Stack direction="row" justifyContent="space-between">
      <Stack spacing={0}>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Circle
            fontSize="small"
            sx={{ color: item.is_veg ? "#00B620" : "#F90202" }}
          />
          <Typography level="body-md">{item.name}</Typography>
        </Stack>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Typography level="body-sm" textColor="neutral.400" fontWeight={700}>
            {variant.name}
          </Typography>
          <Typography level="body-sm" textColor="neutral.400">
            ({getCurrency(restaurant.currency)}
            {variant.price})
          </Typography>
        </Stack>
      </Stack>
      <Typography>x {orderItem.qty}</Typography>
    </Stack>
  ) : null;
}

import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  Modal,
  ModalDialog,
  Typography,
} from "@mui/joy";

import { Delete, WarningRounded } from "@mui/icons-material";

import axios from "axios";

import { getAuthorizationHeader } from "../../../../../utils/helpers";
import {
  setMenuEditor,
  setRooms,
  setTables,
} from "../../../../../redux/restaurant";
import { DOMAIN } from "../../../../../utils/config";
import { setNotify } from "../../../../../redux/utils";

export default function DeleteTable({ table, setOpen }) {
  const { restaurant, tables, rooms } = useSelector(
    (state) => state.restaurant
  );

  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const dispatch = useDispatch();

  const handleDelete = () => {
    setDeleting(true);
    axios({
      method: "DELETE",
      url: `${DOMAIN}/tables/`,
      headers: getAuthorizationHeader(),
      params: {
        organization_uid: restaurant.uid,
        id: table.id,
      },
    })
      .then((res) => {
        dispatch(
          setMenuEditor({
            currentlyEditing: null,
            entryId: null,
            saving: false,
          })
        );
        dispatch(setTables(tables.filter((i) => i.id !== table.id)));
        dispatch(setRooms(rooms.filter((i) => i.id !== table.id)));
        setDeleting(false);
        setOpenDeleteConfirm(false);
        setOpen(false);

        dispatch(
          setNotify({
            open: true,
            action: "Deleted!",
            severity: "success",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      })
      .catch((err) => {
        setOpenDeleteConfirm(false);
        setDeleting(false);

        dispatch(
          setNotify({
            open: true,
            action: "Unable to Delete!",
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      });
  };

  return (
    <>
      <Button
        color="danger"
        size="sm"
        onClick={() => setOpenDeleteConfirm(true)}
        startDecorator={<Delete fontSize="small" />}
      >
        Delete
      </Button>
      <Modal
        open={openDeleteConfirm}
        onClose={() => setOpenDeleteConfirm(false)}
      >
        <ModalDialog variant="outlined" role="alertdialog" maxWidth="400px">
          <DialogTitle>
            <WarningRounded />
            Confirmation
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Typography level="body-sm">
              Are you sure you want to delete <b>{table.name}</b>?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="solid"
              color="danger"
              size="sm"
              onClick={handleDelete}
              disabled={deleting}
            >
              {deleting ? "Deleting" : "Delete"}
            </Button>
            <Button
              variant="plain"
              color="neutral"
              size="sm"
              onClick={() => setOpenDeleteConfirm(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
}

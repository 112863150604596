import { Stack, Typography } from "@mui/joy";

export default function PoweredBy() {
  return (
    <Stack py={2} justifyContent="center" alignItems="center">
      <Typography level="body-xs" component="p">
        Powered By - Smart <b>Dine.in</b>
      </Typography>
    </Stack>
  );
}

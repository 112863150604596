import { renderToString } from "react-dom/server";

import {
  StyleSheet,
  Document,
  Page,
  View,
  Text,
  Image,
} from "@react-pdf/renderer";

import { QRCodeSVG } from "qrcode.react";

import { Canvg } from "canvg";

import ios_camera from "../../../../../assets/images/ios-camera.png";
import apple_logo from "../../../../../assets/images/apple-logo.png";
import google_lens from "../../../../../assets/images/google-lens.png";
import android_logo from "../../../../../assets/images/android-logo.png";
import logo_placeholder from "../../../../../assets/images/restaurant.png";

import { CUSTOMER_PORTAL_BASE_URL } from "../../../../../utils/config";

const style = StyleSheet.create({
  body: {
    backgroundColor: "#F7F7F7",
  },
});

export default function MenuPDF({ restaurant, table }) {
  const svgToDataUri = async (svgString) => {
    try {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      if (context) {
        const v = Canvg.fromString(context, svgString.trim());
        await v.render();
        const dataUri = canvas.toDataURL("image/png");
        return dataUri;
      }
    } catch (error) {
      console.error("[svgToDataUri]", error);
      return null;
    }
  };

  const getQR = async () => {
    let suffix = "";
    if (table && table.is_in_room) suffix = `/room/${table.id}`;
    if (table && !table.is_in_room) suffix = `/table/${table.id}`;

    const svg = QRCodeSVG({
      value: `${CUSTOMER_PORTAL_BASE_URL}/${restaurant.uid}${suffix}`,
      size: 288,
      level: "H",
    });

    return await svgToDataUri(renderToString(svg));
  };

  return (
    <>
      <Document>
        <Page size={{ width: 400, height: 700 }} style={style.body}>
          <View
            style={{
              height: 700,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: 15,
              paddingBottom: 15,
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 15,
                paddingBottom: table ? 10 : 15,
              }}
            >
              <Image
                style={{ width: 58, height: 58, borderRadius: "50%" }}
                src={restaurant.logo ? restaurant.logo : logo_placeholder}
              />
              <Text
                style={{
                  fontSize: 18,
                  textAlign: "center",
                  paddingLeft: "10px",
                }}
              >
                {restaurant.name}
              </Text>
            </View>
            {table && (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: 5,
                  paddingBottom: 0,
                }}
              >
                <Text
                  style={{
                    fontSize: 12,
                    textAlign: "center",
                    color: "#333",
                  }}
                >
                  {table.name}
                </Text>
              </View>
            )}
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 25,
                marginBottom: 20,
              }}
            >
              <Image
                style={{ width: 288, height: 288, paddingBottom: "15px" }}
                src={getQR()}
              />
              <Text
                style={{
                  fontSize: 37,
                  fontWeight: "bold",
                  color: "#333333",
                  textAlign: "center",
                }}
              >
                Scan for
              </Text>
              <Text
                style={{
                  fontSize: 37,
                  fontWeight: "bold",
                  color: "#333333",
                  textAlign: "center",
                }}
              >
                Menu & Order
              </Text>
            </View>
            <View style={{ flex: 1, marginTop: table ? "30px" : "40px" }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: 15,
                }}
              >
                <Text
                  style={{
                    fontSize: 14.3,
                    color: "#666666",
                    paddingRight: "6px",
                  }}
                >
                  Use Camera App
                </Text>
                <Image
                  style={{ width: 25, height: 25 }}
                  src={{
                    uri: ios_camera,
                  }}
                />
                <Text
                  style={{
                    fontSize: 14.3,
                    color: "#666666",
                    paddingRight: "6px",
                    paddingLeft: "6px",
                  }}
                >
                  in iPhone
                </Text>
                <Image
                  style={{ width: 20, height: 24 }}
                  src={{
                    uri: apple_logo,
                  }}
                />
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: 20,
                }}
              >
                <Text
                  style={{
                    fontSize: 14.3,
                    color: "#666666",
                    paddingRight: "6px",
                  }}
                >
                  Use Google Lens
                </Text>
                <Image
                  style={{ width: "auto", height: 22 }}
                  src={{
                    uri: google_lens,
                  }}
                />
                <Text
                  style={{
                    fontSize: 14.3,
                    color: "#666666",
                    paddingRight: "6px",
                    paddingLeft: "6px",
                  }}
                >
                  in Android
                </Text>
                <Image
                  style={{ width: 24, height: "auto" }}
                  src={{
                    uri: android_logo,
                  }}
                />
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: 15,
                }}
              >
                <Text style={{ fontSize: 10, color: "#999999" }}>
                  Powered By -
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    color: "#333333",
                    fontWeight: "bold",
                    paddingLeft: "5px",
                  }}
                >
                  Smart Dine-in
                </Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </>
  );
}

import { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setActivePathId } from "../../../../redux/utils";

import urls from "../../../../utils/urls.json";
import BodyWrapper from "../components/bodyWrapper";
import FilterRow from "./filterRow";
import AvailableList from "./availableList";
import Occupied from "./occupied";
import BookingRequestList from "./bookingRequestList";

import Loading from "../../../../hoc/loading";
import { isMobile } from "../../../../utils/config";

export default function Tables() {
  const { tables, rooms, bookingCodeRequests, sessions } = useSelector(
    (state) => state.restaurant
  );

  const dispatch = useDispatch();

  const [loaded, setLoaded] = useState(false);
  const [bookingRequests, setBookingRequests] = useState([]);
  const [activeSessions, setActiveSessions] = useState([]);
  const [availableTableList, setAvailableTableList] = useState([]);

  useEffect(() => {
    dispatch(setActivePathId(urls.restaurant.children.tables.pathId));
  }, [dispatch]);

  useEffect(() => {
    const activeSessions = sessions.filter((i) => i.booked_on && !i.is_ended);

    const bookedTableIds = activeSessions.map((i) => i.table);

    setActiveSessions(activeSessions);

    setAvailableTableList(
      tables.filter((table) => !bookedTableIds.includes(table.id))
    );

    setLoaded(true);
  }, [rooms, tables, sessions]);

  useEffect(() => {
    const tableList = [];
    for (let i = 0; i < tables.length; i++) {
      const element = tables[i];
      bookingCodeRequests.forEach((bookingCodeRequest) => {
        if (bookingCodeRequest.table_id === element.id) {
          tableList.push({
            ...element,
            bookingCode: bookingCodeRequest.booking_code,
            bookingCustomerName: bookingCodeRequest.customer_name,
            bookingCustomerPhone: bookingCodeRequest.customer_phone,
            sessionId: bookingCodeRequest.session_id,
          });
        }
      });
    }
    setBookingRequests(tableList);
  }, [rooms, tables, bookingCodeRequests]);

  return (
    <BodyWrapper withStack>
      {!isMobile() && <FilterRow />}
      <Loading loading={!loaded}>
        <BookingRequestList bookingRequests={bookingRequests} />
        <Occupied activeSessions={activeSessions} />
        <AvailableList availableTableList={availableTableList} />
      </Loading>
    </BodyWrapper>
  );
}

import { BrowserRouter, Routes, Route } from "react-router-dom";

// auth pages
import Login from "./pages/auth/login";
import Signup from "./pages/auth/signup";
import ForgotPassword from "./pages/auth/forgotPassword";
import ResetPassword from "./pages/auth/resetPassword";
import SignupVerification from "./pages/auth/signupVerification";

// account pages
import Account from "./pages/account/account";

// dashboard pages
import Dashboard from "./pages/dashboard/dashboard";

// restaurant pages
import Summary from "./pages/dashboard/restaurant/summary";
import Orders from "./pages/dashboard/restaurant/orders";
import Tables from "./pages/dashboard/restaurant/tables";
import Menu from "./pages/dashboard/restaurant/menu";
import Customers from "./pages/dashboard/restaurant/customers";
import Coupons from "./pages/dashboard/restaurant/coupons";
import Billing from "./pages/dashboard/restaurant/billing";
import Settings from "./pages/dashboard/restaurant/settings";

// setting pages
import RestaurantDetails from "./pages/dashboard/restaurant/settings/restaurantDetails";
import Timings from "./pages/dashboard/restaurant/settings/timings";
import Dates from "./pages/dashboard/restaurant/settings/dates";
import Team from "./pages/dashboard/restaurant/settings/team";
import Permissions from "./pages/dashboard/restaurant/settings/permissions";

// hoc
import AppWrapper from "./hoc/wrapper";
import Protected from "./hoc/protected";
import Unprotected from "./hoc/unprotected";
import AnyAuth from "./hoc/anyAuth";

// utils
import urls from "./utils/urls.json";

export default function App() {
  const authRoutes = () => (
    <>
      <Route path={urls.auth.path} element={<Unprotected />}>
        <Route path={urls.auth.children.login.path} element={<Login />}></Route>
        <Route
          path={urls.auth.children.signup.path}
          element={<Signup />}
        ></Route>
        <Route
          path={urls.auth.children.forgotPassword.path}
          element={<ForgotPassword />}
        ></Route>
        <Route
          path={urls.auth.children.resetPassword.path}
          element={<ResetPassword />}
        ></Route>
      </Route>
      <Route path={urls.auth.path} element={<AnyAuth />}>
        <Route
          path={urls.auth.children.signupVerification.path}
          element={<SignupVerification />}
        ></Route>
      </Route>
    </>
  );

  const accountRoutes = () => (
    <Route path={urls.account.path} element={<Protected />}>
      <Route
        path={urls.account.children.account.path}
        element={<Account />}
      ></Route>
    </Route>
  );

  const dashboardRoues = () => (
    <Route path={urls.dashboard.path} element={<Protected />}>
      <Route path={urls.dashboard.path} element={<Dashboard />}></Route>
    </Route>
  );

  const restaurantRoues = () => (
    <Route path={urls.dashboard.path} element={<Protected restaurantLayout />}>
      <Route path={urls.restaurant.path} element={<Summary />}></Route>
      <Route
        path={urls.restaurant.children.orders.absolutePath}
        element={<Orders />}
      ></Route>
      <Route
        path={urls.restaurant.children.tables.absolutePath}
        element={<Tables />}
      ></Route>
      <Route
        path={urls.restaurant.children.menu.absolutePath}
        element={<Menu />}
      ></Route>
      <Route
        path={urls.restaurant.children.customers.absolutePath}
        element={<Customers />}
      ></Route>
      <Route
        path={urls.restaurant.children.coupons.absolutePath}
        element={<Coupons />}
      ></Route>
      <Route
        path={urls.restaurant.children.billing.absolutePath}
        element={<Billing />}
      ></Route>
      <Route
        path={urls.restaurant.children.settings.absolutePath}
        element={<Settings />}
      ></Route>
      <Route
        path={
          urls.restaurant.children.settings.children.restaurantDetails
            .absolutePath
        }
        element={<RestaurantDetails />}
      ></Route>
      <Route
        path={urls.restaurant.children.settings.children.timings.absolutePath}
        element={<Timings />}
      ></Route>
      <Route
        path={urls.restaurant.children.settings.children.dates.absolutePath}
        element={<Dates />}
      ></Route>
      <Route
        path={urls.restaurant.children.settings.children.team.absolutePath}
        element={<Team />}
      ></Route>
      <Route
        path={
          urls.restaurant.children.settings.children.permissions.absolutePath
        }
        element={<Permissions />}
      ></Route>
    </Route>
  );

  return (
    <BrowserRouter>
      <AppWrapper>
        <Routes>
          {dashboardRoues()}
          {accountRoutes()}
          {authRoutes()}
          {restaurantRoues()}
        </Routes>
      </AppWrapper>
    </BrowserRouter>
  );
}

import { useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import {
  setItems,
  setMenuEditor,
  setVariants,
} from "../../../../redux/restaurant";
import { setNotify } from "../../../../redux/utils";

import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalDialog,
  DialogTitle,
  DialogContent,
  Stack,
  Textarea,
  Select,
  Option,
  Typography,
} from "@mui/joy";

import { Add, Circle } from "@mui/icons-material";

import axios from "axios";

import { DOMAIN, getCurrencyIcon } from "../../../../utils/config";
import { getAuthorizationHeader } from "../../../../utils/helpers";

export default function AddItem() {
  const { restaurant, itemCategories, items, variants } = useSelector(
    (state) => state.restaurant
  );

  const [open, setOpen] = useState(false);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState(0);
  const [isVeg, setIsVeg] = useState(true);
  const [saving, setSaving] = useState(false);

  const dispatch = useDispatch();

  const handleSave = (e) => {
    e.preventDefault();
    setSaving(true);
    axios({
      method: "POST",
      url: `${DOMAIN}/menus/items/`,
      headers: getAuthorizationHeader(),
      params: {
        uid: restaurant.uid,
      },
      data: {
        name,
        description,
        is_veg: isVeg,
        category,
        price,
      },
    })
      .then((res) => {
        setSaving(false);
        setName("");
        setDescription("");
        setPrice("");
        setOpen(false);
        let newItem = res.data;
        let newVariants = newItem.variants;
        delete newItem["variants"];
        dispatch(setItems([...items, newItem]));
        dispatch(setVariants([...variants, ...newVariants]));
        dispatch(
          setNotify({
            open: true,
            action: "Item Added!",
            severity: "success",
            autoHideDuration: 2000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
        dispatch(
          setMenuEditor({
            currentlyEditing: "item",
            entryId: res.data.id,
            saving: false,
          })
        );
      })
      .catch((err) => {
        setSaving(false);
        let errorMessage = "Something went Wrong!";
        try {
          errorMessage = JSON.parse(err.request.response).message;
        } catch (error) {}
        dispatch(
          setNotify({
            open: true,
            action: errorMessage,
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      });
  };

  return (
    <>
      <Button
        variant="outlined"
        color="neutral"
        size="sm"
        startDecorator={<Add />}
        onClick={() => setOpen(true)}
      >
        Add Item
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog minWidth="380px">
          <DialogTitle>Create New Item</DialogTitle>
          <DialogContent>Fill in the information of the Item.</DialogContent>
          <form onSubmit={handleSave}>
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  size="sm"
                  autoFocus
                  required
                  placeholder="eg. Margheria Pizza"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Description</FormLabel>
                <Textarea
                  size="sm"
                  minRows={2}
                  maxRows={3}
                  placeholder="Add a short description about the item."
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Price</FormLabel>
                <Input
                  size="sm"
                  required
                  placeholder="eg. 449"
                  type="number"
                  startDecorator={getCurrencyIcon(restaurant.currency)}
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Category</FormLabel>
                <Select
                  size="sm"
                  value={category}
                  onChange={(_, newValue) => setCategory(newValue)}
                >
                  <Option value={0} disabled>
                    Select a Category
                  </Option>
                  {itemCategories.map((category, index) => (
                    <Option value={category.id} key={index}>
                      {category.name}
                    </Option>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Veg/Non-veg</FormLabel>
                <Select
                  size="sm"
                  value={isVeg}
                  onChange={() => setIsVeg(!isVeg)}
                >
                  <Option value={true}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Circle fontSize="small" sx={{ color: "#00B620" }} />
                      <Typography level="body-sm">Veg</Typography>
                    </Stack>
                  </Option>
                  <Option value={false}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Circle fontSize="small" sx={{ color: "#F90202" }} />
                      <Typography level="body-sm">Non-veg</Typography>
                    </Stack>
                  </Option>
                </Select>
              </FormControl>
              <Button type="submit" color="neutral" size="sm" disabled={saving}>
                {saving ? "Creating" : "Create"}
              </Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </>
  );
}

import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";

import { Stack, Button, Modal, Typography, Sheet } from "@mui/joy";

import { ReceiptLong } from "@mui/icons-material";

import { getAuthorizationHeader } from "../../../../../utils/helpers";
import { DOMAIN } from "../../../../../utils/config";
import { setNotify } from "../../../../../redux/utils";

import axios from "axios";
import { setOrders, setSessions } from "../../../../../redux/restaurant";

export default function MarkedAsBilled({ table, sessionId }) {
  const { sessions, orders, pendingOrders } = useSelector(
    (state) => state.restaurant
  );

  const [open, setOpen] = useState(false);
  const [sending, setSending] = useState(false);

  const { restaurantId } = useParams();

  const dispatch = useDispatch();

  const handleRequest = () => {
    setSending(true);
    axios({
      method: "POST",
      url: `${DOMAIN}/orders/bill-paid/`,
      headers: getAuthorizationHeader(),
      params: {
        organization_uid: restaurantId,
      },
      data: {
        session_id: sessionId,
      },
    })
      .then((res) => {
        const sessionOrders = res.data.orders;
        const session = res.data.session;
        const updatedOrders = [];

        orders.forEach((stateOrder) => {
          const sessionOrder = sessionOrders.find(
            (i) => i.id === stateOrder.id
          );

          updatedOrders.push(sessionOrder || stateOrder);
        });

        pendingOrders.forEach((statePendingOrder) => {
          const sessionOrder = sessionOrders.find(
            (i) => i.id === statePendingOrder.id
          );

          if (sessionOrder) {
            updatedOrders.push(sessionOrder);
          }
        });

        dispatch(setOrders(updatedOrders));

        dispatch(
          setSessions(
            sessions.map((i) => {
              if (i.id === sessionId) return session;
              return i;
            })
          )
        );

        setOpen(false);
        setSending(false);
        dispatch(
          setNotify({
            open: true,
            action: `${table.name} is now available!`,
            severity: "success",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "center",
          })
        );
      })
      .catch((err) => {
        setSending(false);
        setOpen(false);
        let errorMessage = "Something went Wrong!";
        try {
          errorMessage = JSON.parse(err.request.response).message;
        } catch (error) {}
        dispatch(
          setNotify({
            open: true,
            action: errorMessage,
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "center",
          })
        );
      });
  };

  return (
    <>
      <Button
        fullWidth
        startDecorator={<ReceiptLong />}
        variant="solid"
        color="success"
        size="sm"
        onClick={() => setOpen(true)}
      >
        Billed
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          px: 1,
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            minWidth: "300px",
            borderRadius: "md",
            p: 3,
          }}
        >
          <Stack spacing={3}>
            <Stack spacing={1}>
              <Typography level="title-sm">
                Have you billed <b>{table.name}</b>?
              </Typography>
              <Typography level="body-xs" textColor="neutral.400">
                Once marked as billed, the customer won't be able to place more
                orders.
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1.5}
            >
              <Button
                fullWidth
                color="neutral"
                variant="outlined"
                disabled={sending}
                size="sm"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
              <Button
                fullWidth
                color="neutral"
                disabled={sending}
                size="sm"
                onClick={handleRequest}
              >
                Billed
              </Button>
            </Stack>
          </Stack>
        </Sheet>
      </Modal>
    </>
  );
}

import { Stack, Typography, Grid } from "@mui/joy";

import Order from "../components/order";

export default function Served({ servedOrders }) {
  return (
    <Stack spacing={1}>
      <Typography level="title-lg">
        <b>Served</b>({servedOrders.length})
      </Typography>
      <Grid container rowSpacing={2} columnSpacing={3}>
        {servedOrders.length ? (
          servedOrders.map((order, index) => (
            <Order key={index} order={order} />
          ))
        ) : (
          <Stack
            minHeight="100px"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Typography
              textAlign="center"
              level="body-sm"
              textColor="neutral.400"
            >
              No Served Orders!
            </Typography>
          </Stack>
        )}
      </Grid>
    </Stack>
  );
}

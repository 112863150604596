import { Close } from "@mui/icons-material";
import { IconButton, Sheet, Stack, Typography } from "@mui/joy";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setShowFreeTrialBanner } from "../../redux/utils";
import moment from "moment";

export default function FreeTrialBanner() {
  const { restaurant } = useSelector((state) => state.restaurant);
  const { showFreeTrialBanner } = useSelector((state) => state.utils);

  const dispatch = useDispatch();

  const shouldDisplay = () => {
    if (!showFreeTrialBanner) return false;
    return true;
  };

  return (
    <Stack
      pb={2}
      sx={{
        display: shouldDisplay() ? "block" : "none",
      }}
    >
      <Sheet
        sx={{
          bgcolor: "#ffbe1a",
          borderRadius: "6px",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignContent="space-between"
          alignItems="center"
          paddingX={2}
          paddingY={1}
        >
          <Stack spacing={0.25}>
            <Stack direction="row" spacing={1}>
              <Typography level="body-sm" fontWeight={500}>
                Your Free Trial is ending on
              </Typography>
              <Typography level="body-sm" fontWeight={700}>
                {moment(restaurant.trial_ending_on).format("DD MMM, YYYY")}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={0.5}>
              <Typography level="body-xs" textColor="neutral.500">
                Setup
              </Typography>
              <Link
                to={restaurant.subscription.razor_short_url}
                target="_blank"
              >
                <Typography
                  level="body-xs"
                  textColor="neutral.500"
                  sx={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Automatic Payments.
                </Typography>
              </Link>
              <Typography level="body-xs" textColor="neutral.500">
                You'll be charged only after the trial period ends.
              </Typography>
            </Stack>
          </Stack>
          <IconButton
            size="sm"
            onClick={() => dispatch(setShowFreeTrialBanner(false))}
          >
            <Close />
          </IconButton>
        </Stack>
      </Sheet>
    </Stack>
  );
}

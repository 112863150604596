import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  Modal,
  ModalDialog,
  IconButton,
  Typography,
} from "@mui/joy";

import { Delete, WarningRounded } from "@mui/icons-material";

import axios from "axios";

import { getAuthorizationHeader } from "../../../../../utils/helpers";
import { setVariants } from "../../../../../redux/restaurant";
import { DOMAIN } from "../../../../../utils/config";
import { setNotify } from "../../../../../redux/utils";

export default function DeleteVariant({ variant }) {
  const { restaurant, variants } = useSelector((state) => state.restaurant);

  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const dispatch = useDispatch();

  const handleDelete = () => {
    setDeleting(true);
    axios({
      method: "DELETE",
      url: `${DOMAIN}/menus/item-variants/`,
      headers: getAuthorizationHeader(),
      params: {
        uid: restaurant.uid,
        id: variant.id,
      },
    })
      .then((res) => {
        dispatch(setVariants(variants.filter((i) => i.id !== variant.id)));
        setDeleting(false);
        setOpenDeleteConfirm(false);

        dispatch(
          setNotify({
            open: true,
            action: "Variant Deleted!",
            severity: "success",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      })
      .catch((err) => {
        setDeleting(false);

        let errorMessage = "Unable to Delete!";
        try {
          errorMessage = JSON.parse(err.request.response).message;
        } catch (error) {}
        dispatch(
          setNotify({
            open: true,
            action: errorMessage,
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      });
  };

  return (
    <>
      <IconButton
        color="danger"
        size="sm"
        onClick={() => setOpenDeleteConfirm(true)}
      >
        <Delete />
      </IconButton>
      <Modal
        open={openDeleteConfirm}
        onClose={() => setOpenDeleteConfirm(false)}
      >
        <ModalDialog variant="outlined" role="alertdialog" maxWidth="400px">
          <DialogTitle>
            <WarningRounded />
            Confirmation
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Typography level="body-sm">
              Are you sure you want to delete <b>{variant.name}</b>?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="solid"
              color="danger"
              size="sm"
              onClick={handleDelete}
              disabled={deleting}
            >
              {deleting ? "Deleting" : "Delete"}
            </Button>
            <Button
              variant="plain"
              color="neutral"
              size="sm"
              onClick={() => setOpenDeleteConfirm(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
}

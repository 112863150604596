import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { setActivePathId } from "../../../../redux/utils";

import { Typography } from "@mui/joy";

import urls from "../../../../utils/urls.json";
import BodyWrapper from "../components/bodyWrapper";

export default function Customers() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActivePathId(urls.restaurant.children.customers.pathId));
  }, [dispatch]);
  return (
    <BodyWrapper withStack>
      <Typography>Customers</Typography>
    </BodyWrapper>
  );
}

import { useSelector } from "react-redux";

import { Grid, IconButton, Stack, Tooltip, Typography } from "@mui/joy";
import { Download } from "@mui/icons-material";

import moment from "moment";
import { Link } from "react-router-dom";
import { getCurrency } from "../../../../../utils/config";

export default function Payment({ isHeader, payment }) {
  const { restaurant } = useSelector((state) => state.restaurant);

  return (
    <Grid
      container
      bgcolor="white"
      rowSpacing={isHeader ? 3 : 3.5}
      columnSpacing={3}
      justifyContent="center"
      alignItems="center"
      border="1px solid #e2e2e2"
      borderRadius="6px"
    >
      <Grid xs={3}>
        <Typography level="body-md" fontWeight={700}>
          {isHeader
            ? "Date"
            : payment.created_on
            ? moment(payment.created_on).format("DD MMM, YYYY")
            : "N/A"}
        </Typography>
      </Grid>
      <Grid xs={4}>
        <Stack direction="row" spacing={1}>
          <Typography
            level={isHeader ? "body-md" : "body-sm"}
            fontWeight={isHeader && 700}
            textColor={isHeader ? "neutral.500" : "neutral.400"}
          >
            {payment.description || "Payment Towards Subscription"}
          </Typography>
          <Link to={payment.retryLink} target="_blank">
            <Typography
              level="body-sm"
              sx={{
                display: payment.retryLink ? "block" : "none",
                textDecoration: "underline",
              }}
            >
              Retry now
            </Typography>
          </Link>
        </Stack>
      </Grid>
      <Grid xs={2}>
        <Typography level="body-md" fontWeight={700}>
          {!isHeader && payment.razor_amount
            ? getCurrency(restaurant.currency)
            : null}
          {isHeader
            ? "Amount"
            : payment.razor_amount
            ? payment.razor_amount / 100
            : "N/A"}
        </Typography>
      </Grid>
      <Grid xs={2}>
        <Typography
          level="body-md"
          fontWeight={700}
          textColor={
            isHeader
              ? "neutral.500"
              : payment.is_successful
              ? "success.500"
              : "danger.500"
          }
        >
          {isHeader
            ? "Status"
            : payment.is_successful
            ? "Successful"
            : "Failed"}
        </Typography>
      </Grid>
      <Grid xs={1}>
        {isHeader ? (
          <Typography
            level="body-md"
            fontWeight={isHeader && 700}
            textAlign="right"
          >
            Invoice
          </Typography>
        ) : (
          <Stack direction="row" justifyContent="flex-end">
            <Tooltip title="Not available" size="sm">
              <IconButton size="sm">
                <Download />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
      </Grid>
    </Grid>
  );
}

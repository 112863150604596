import { useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { setRooms, setTables } from "../../../../../redux/restaurant";
import { setNotify } from "../../../../../redux/utils";

import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalDialog,
  DialogTitle,
  DialogContent,
  Stack,
  IconButton,
  Divider,
  Grid,
  Typography,
  Textarea,
  Switch,
} from "@mui/joy";

import { Check, Close, DriveFileRenameOutline } from "@mui/icons-material";

import axios from "axios";

import { DOMAIN, isMobile } from "../../../../../utils/config";
import {
  getAuthorizationHeader,
  getTagIcon,
} from "../../../../../utils/helpers";
import DeleteTable from "./deleteTable";

export default function EditTable({ table, isRoom }) {
  const typeName = isRoom ? "Room" : "Table";

  const { restaurant, tables, rooms, tableTags } = useSelector(
    (state) => state.restaurant
  );

  const getSelectedTags = (table) => {
    let selectedTags = [];
    for (let i = 0; i < tableTags.length; i++) {
      const element = tableTags[i];
      const exists = table.tags.filter((j) => j === element.id);
      if (exists.length) selectedTags = [...selectedTags, element];
    }
    return selectedTags;
  };

  const [open, setOpen] = useState(false);

  const [name, setName] = useState(table.name);
  const [description, setDescription] = useState(table.description || "");
  const [capacity, setCapacity] = useState(table.capacity);
  const [isLive, setIsLive] = useState(table.is_live);
  const [saving, setSaving] = useState(false);
  const [selectedTags, setSelectedTags] = useState(getSelectedTags(table));

  const dispatch = useDispatch();

  const handleSave = (e) => {
    e.preventDefault();
    setSaving(true);
    const payload = {};

    if (name !== table.name) payload.name = name;
    if (description !== table.description) payload.description = description;
    if (capacity !== table.capacity) payload.capacity = capacity;
    if (isLive !== table.is_live) payload.is_live = isLive;
    if (selectedTags !== getSelectedTags(table))
      payload.tags = selectedTags.map((i) => i.id);

    axios({
      method: "PATCH",
      url: `${DOMAIN}/tables/`,
      headers: getAuthorizationHeader(),
      params: {
        organization_uid: restaurant.uid,
        id: table.id,
      },
      data: payload,
    })
      .then((res) => {
        const data = res.data;
        if (isRoom)
          dispatch(
            setRooms(
              rooms.map((i) => {
                if (i.id === table.id) {
                  return data;
                } else return i;
              })
            )
          );
        else
          dispatch(
            setTables(
              tables.map((i) => {
                if (i.id === table.id) {
                  return data;
                } else return i;
              })
            )
          );

        setName(data.name);
        setDescription(data.description);
        setCapacity(data.capacity);
        setIsLive(data.is_live);
        setSaving(false);
        setSelectedTags(getSelectedTags(data));

        dispatch(
          setNotify({
            open: true,
            action: `${typeName}(s) Saved!`,
            severity: "success",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
        setOpen(false);
      })
      .catch((err) => {
        setSaving(false);
        let errorMessage = "Something went Wrong!";
        try {
          errorMessage = JSON.parse(err.request.response).message;
        } catch (error) {}
        dispatch(
          setNotify({
            open: true,
            action: errorMessage,
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      });
  };

  const getUnselectedTags = () => {
    return tableTags.filter((t) => !selectedTags.includes(t));
  };

  const handleUnselectTag = (tag) => {
    setSelectedTags(selectedTags.filter((t) => t.id !== tag.id));
  };

  const handleSelectTag = (tag) => {
    setSelectedTags([...selectedTags, tag]);
  };

  return (
    <>
      <Button
        fullWidth
        startDecorator={<DriveFileRenameOutline />}
        variant="outlined"
        color="neutral"
        size="sm"
        onClick={() => setOpen(true)}
        disabled={isMobile()}
      >
        Edit {typeName}
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog minWidth="450px">
          <DialogTitle>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Typography level="title-lg">Edit {name}</Typography>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Switch
                  size="sm"
                  color="neutral"
                  variant={isLive ? "solid" : "outlined"}
                  checked={isLive}
                  onChange={() => setIsLive(!isLive)}
                />
                <DeleteTable table={table} setOpen={setOpen} />
              </Stack>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Typography level="body-sm" textColor="neutral.400">
              Fill in the updated information of the {typeName}.
            </Typography>
          </DialogContent>
          <form onSubmit={handleSave}>
            <Stack spacing={2}>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel>{typeName} Title</FormLabel>
                <Input
                  fullWidth
                  size="sm"
                  required
                  placeholder={`Eg. ${typeName} 101`}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel>Description (Optional)</FormLabel>
                <Textarea
                  variant="soft"
                  size="sm"
                  minRows={3}
                  maxRows={3}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Capacity</FormLabel>
                <Input
                  fullWidth
                  size="sm"
                  required
                  type="number"
                  placeholder="Eg. 4"
                  value={capacity}
                  onChange={(e) => setCapacity(e.target.value)}
                />
              </FormControl>
              {!isRoom && <Divider orientation="horizontal" />}
              {!isRoom && (
                <>
                  <Stack spacing={2}>
                    <Grid container>
                      <Grid xs={6}>
                        <Stack spacing={1}>
                          <Typography level="title-sm">
                            <b>Selected Tags</b> ({selectedTags.length})
                          </Typography>
                          {selectedTags.length ? (
                            selectedTags.map((tag, index) => (
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                key={index}
                              >
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={1}
                                >
                                  {getTagIcon(tag)}
                                  <Typography level="body-xs">
                                    {tag.name}
                                  </Typography>
                                </Stack>
                                <IconButton
                                  size="sm"
                                  onClick={() => handleUnselectTag(tag)}
                                >
                                  <Close />
                                </IconButton>
                              </Stack>
                            ))
                          ) : (
                            <Stack
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                              minHeight="200px"
                            >
                              <Typography level="body-xs">
                                No Tags Selected
                              </Typography>
                            </Stack>
                          )}
                        </Stack>
                      </Grid>
                      <Grid xs={6} paddingLeft={2} minHeight="250px">
                        <Stack spacing={1}>
                          <Typography level="title-sm">
                            <b>Available Tags</b>
                          </Typography>
                          {getUnselectedTags().length ? (
                            getUnselectedTags().map((tag, index) => (
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                key={index}
                              >
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={1}
                                >
                                  {getTagIcon(tag)}
                                  <Typography level="body-xs">
                                    {tag.name}
                                  </Typography>
                                </Stack>
                                <IconButton
                                  size="sm"
                                  onClick={() => handleSelectTag(tag)}
                                >
                                  <Check />
                                </IconButton>
                              </Stack>
                            ))
                          ) : (
                            <Stack
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                              minHeight="200px"
                            >
                              <Typography level="body-xs">
                                All Tags Selected
                              </Typography>
                            </Stack>
                          )}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Stack>
                </>
              )}
              <Stack direction="row" spacing={2}>
                <Button
                  fullWidth
                  color="neutral"
                  variant="outlined"
                  size="sm"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  fullWidth
                  type="submit"
                  color="neutral"
                  size="sm"
                  disabled={saving || !capacity}
                >
                  {saving ? "Saving" : "Save"}
                </Button>
              </Stack>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </>
  );
}

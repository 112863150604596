import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { SystemUpdateAlt } from "@mui/icons-material";
import { Stack, Button, Modal, ModalClose, Typography, Sheet } from "@mui/joy";

import { DOMAIN } from "../../../../utils/config";
import { getAuthorizationHeader } from "../../../../utils/helpers";
import { setNotify } from "../../../../redux/utils";

import axios from "axios";

export default function LoadMenuTemplate() {
  const { restaurant, menu, itemCategories } = useSelector(
    (state) => state.restaurant
  );

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleDelete = () => {
    setLoading(true);
    axios({
      method: "POST",
      url: `${DOMAIN}/menus/load/`,
      headers: getAuthorizationHeader(),
      params: {
        organization_uid: restaurant.uid,
        menu_id: menu.id,
      },
    })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        setOpen(false);
        setLoading(false);
        let errorMessage = "Something went Wrong!";
        try {
          errorMessage = JSON.parse(err.request.response).message;
        } catch (error) {}
        dispatch(
          setNotify({
            open: true,
            action: errorMessage,
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      });
  };

  return (
    <>
      <Button
        variant="solid"
        color="neutral"
        size="sm"
        startDecorator={<SystemUpdateAlt />}
        onClick={() => setOpen(true)}
        sx={{
          display: itemCategories.length ? "none" : "flex",
        }}
      >
        Load Menu
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 450,
            minWidth: 400,
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Stack spacing={2}>
            <Typography
              component="h2"
              id="modal-title"
              level="h4"
              fontWeight="lg"
            >
              Load Menu Template
            </Typography>
            <Stack spacing={1}>
              <Typography level="body-md" textColor="neutral.400">
                You'll be adding a bunch of Categories such as <b>Veg Pizzas</b>
                , <b>Non-veg Pizzas</b>, <b>Breads</b>, etc. and a few items in
                these categories.
              </Typography>
              <Typography level="body-md" textColor="neutral.400">
                You can modify these Categories and Items easily according your
                requirements.
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                color="neutral"
                disabled={loading}
                size="sm"
                onClick={handleDelete}
              >
                Load Menu
              </Button>
            </Stack>
          </Stack>
        </Sheet>
      </Modal>
    </>
  );
}

import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { StopCircle } from "@mui/icons-material";
import {
  FormControl,
  Input,
  Stack,
  Button,
  Modal,
  ModalClose,
  Typography,
  Sheet,
  IconButton,
  Tooltip,
} from "@mui/joy";

import { DOMAIN } from "../../../../../utils/config";
import { getAuthorizationHeader } from "../../../../../utils/helpers";
import { setNotify } from "../../../../../redux/utils";

import axios from "axios";

export default function EndSubscription() {
  const { restaurant } = useSelector((state) => state.restaurant);

  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [deleting, setDeleting] = useState(false);

  const dispatch = useDispatch();

  const handleEnd = () => {
    setDeleting(true);
    axios({
      method: "DELETE",
      url: `${DOMAIN}/billings/subscription/`,
      headers: getAuthorizationHeader(),
      params: {
        organization_uid: restaurant.uid,
      },
    })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        setOpen(false);
        setText("");
        setDeleting(false);
        let errorMessage = "Something went Wrong!";
        try {
          errorMessage = JSON.parse(err.request.response).message;
        } catch (error) {}
        dispatch(
          setNotify({
            open: true,
            action: errorMessage,
            severity: "danger",
            autoHideDuration: 10000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      });
  };

  return (
    <>
      <Tooltip title="End Subscription" color="danger" size="sm">
        <IconButton size="sm" onClick={() => setOpen(true)}>
          <StopCircle />
        </IconButton>
      </Tooltip>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 500,
            minWidth: 400,
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Stack spacing={1}>
            <Typography
              component="h2"
              id="modal-title"
              level="h4"
              fontWeight="lg"
            >
              Unsubscribe from {restaurant.plan_code} Plan?
            </Typography>
            <Typography level="body-xs">
              To confirm please write "CONFIRM" in th box below?
            </Typography>
            <Stack spacing={2}>
              <FormControl>
                <Input
                  required
                  size="sm"
                  type="text"
                  value={text}
                  placeholder="CONFIRM"
                  onChange={(e) => {
                    setText(e.target.value);
                  }}
                />
              </FormControl>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  color="neutral"
                  disabled={deleting}
                  size="sm"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  color="danger"
                  disabled={deleting || text !== "CONFIRM"}
                  size="sm"
                  onClick={handleEnd}
                >
                  Unsubscribe
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Sheet>
      </Modal>
    </>
  );
}

import { Stack, Input, Button, Tooltip, Typography } from "@mui/joy";
import { Search, FilterList } from "@mui/icons-material";
import AddTables from "./addTables";

export default function FilterRow({ isRoom }) {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" alignItems="center" spacing={1.5}>
        <Tooltip size="sm" title="Coming Soon.." variant="solid">
          <Button
            variant="outlined"
            color="neutral"
            size="sm"
            startDecorator={<FilterList />}
          >
            <Typography level="body-sm" textColor="neutral.400">
              Filters
            </Typography>
          </Button>
        </Tooltip>
        <Tooltip size="sm" title="Coming Soon.." variant="solid">
          <Input
            startDecorator={<Search />}
            placeholder="Search in Tables"
            size="sm"
            sx={{
              minWidth: "300px",
            }}
          />
        </Tooltip>
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1.5}
      >
        <AddTables isRoom={isRoom} />
      </Stack>
    </Stack>
  );
}

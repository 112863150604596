import { Stack } from "@mui/joy";
import Header from "./header";
import { SIDEBAR_WIDTH } from "../layout";
import FreeTrialBanner from "../../../../components/banners/freeTrial";
import { isMobile } from "../../../../utils/config";

export default function BodyWrapper({ children, withStack }) {
  const { innerWidth } = window;

  return (
    <Stack
      sx={{
        width: {
          xs: `${innerWidth}px`,
          lg: `${innerWidth - SIDEBAR_WIDTH}px`,
        },
      }}
    >
      <Header />
      <Stack
        sx={{
          height: "100%",
          padding: {
            xs: "0px 20px 5px 20px",
            lg: "0px 35px 15px 35px",
          },
        }}
        spacing={2}
      >
        {!isMobile() && <FreeTrialBanner />}
        {withStack ? (
          <Stack
            spacing={2.5}
            sx={{
              marginTop: "0px !important",
            }}
          >
            {children}
          </Stack>
        ) : (
          children
        )}
      </Stack>
    </Stack>
  );
}

import { Stack } from "@mui/joy";
import AddCategory from "./addCategory";
import AddItem from "./addItem";
import LoadMenuTemplate from "./loadMenuTemplate";

export default function FilterRow() {
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      sx={{
        position: "sticky",
        top: "0px",
        zIndex: 1,
        bgcolor: "#fafafa",
      }}
      pb={1}
    >
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1.5}
      >
        <LoadMenuTemplate />
        <AddCategory />
        <AddItem />
      </Stack>
    </Stack>
  );
}

import { AttachMoney, CurrencyRupee } from "@mui/icons-material";

const getDomain = () => {
  if (process.env.NODE_ENV === "development")
    return `${window.location.protocol}//${window.location.hostname}:8000`;
  return "https://api.smartdine.in";
};

export const DOMAIN = getDomain();

export const MAX_MOBILE_SIZE = 1199;

export const isMobile = () => {
  return window.innerWidth <= MAX_MOBILE_SIZE;
};

export const LANDING_PAGE_BASE_URL = "https://www.smartdine.in";
export const ADMIN_PORTAL_BASE_URL = "https://dash.smartdine.in";
export const CUSTOMER_PORTAL_BASE_URL = "https://app.smartdine.in";

export const CONTACT_US_PAGE = "https://www.smartdine.in/contact-us";

export const TERMS_AND_CONDITIONS_PAGE =
  "https://www.smartdine.in/terms-and-conditions";

export const PLAN = {
  BASIC: "BASIC",
  PRO: "PRO",
  ENTERPRISE: "ENTERPRISE",
};

export const PLAN_LEVEL = {
  [PLAN.BASIC]: 1,
  [PLAN.PRO]: 2,
  [PLAN.ENTERPRISE]: 3,
};

export const checkIfSubscribedTo = (restaurantPlan, requiredPlan) => {
  return restaurantPlan
    ? PLAN_LEVEL[restaurantPlan] >= PLAN_LEVEL[requiredPlan]
    : false;
};

export const BASIC_PLAN_BENEFITS = [
  {
    name: "QR Menu",
    isIncluded: true,
  },

  {
    name: "Orders",
    isIncluded: false,
  },
  {
    name: "Table Management",
    isIncluded: false,
  },
  {
    name: "Create Coupons",
    isIncluded: false,
  },
  {
    name: "Outlet Summary",
    isIncluded: false,
  },
  {
    name: "See Customers",
    isIncluded: false,
  },
];

export const PRO_PLAN_BENEFITS = [
  {
    name: "QR Menu",
    isIncluded: true,
  },

  {
    name: "Orders",
    isIncluded: true,
  },
  {
    name: "Table Management",
    isIncluded: true,
  },
  {
    name: "Create Coupons",
    isIncluded: false,
  },
  {
    name: "Outlet Summary",
    isIncluded: false,
  },
  {
    name: "See Customers",
    isIncluded: false,
  },
];

export const ENTERPRISE_PLAN_BENEFITS = [
  {
    name: "QR Menu",
    isIncluded: true,
  },

  {
    name: "Orders",
    isIncluded: true,
  },
  {
    name: "Table Management",
    isIncluded: true,
  },
  {
    name: "Create Coupons",
    isIncluded: true,
  },
  {
    name: "Outlet Summary",
    isIncluded: true,
  },
  {
    name: "See Customers",
    isIncluded: true,
  },
];

export const FIREBASE_CONFIG = {
  COLLECTION_NAME: "organizations",
  CALLING_TABLES: "CALLING_TABLES",
  BILL_REQUESTS: "BILL_REQUESTS",
  BOOKING_CODES: "BOOKING_CODES",
  ACTIVE_ORDERS: "ACTIVE_ORDERS",
};

const getCurrencySymbol = (currency_code) => {
  if (currency_code === "USD") return "$";
  return "₹";
};

export const getCurrency = (object) => {
  // object must be an organization(object) or a currency_code(string).
  // Supported: INR/USD
  if (typeof object === "object") {
    return getCurrencySymbol(object.currency);
  }
  return getCurrencySymbol(object);
};

const getCurrencySymbolIcon = (currency_code) => {
  if (currency_code === "USD") return <AttachMoney />;
  return <CurrencyRupee />;
};

export const getCurrencyIcon = (object) => {
  // object must be an organization(object) or a currency_code(string).
  // Supported: INR/USD
  if (typeof object === "object") {
    return getCurrencySymbolIcon(object.currency);
  }
  return getCurrencySymbolIcon(object);
};

import { useSelector } from "react-redux";

import { Stack, Button, Tooltip, Typography } from "@mui/joy";
import { Download, FilterList, OpenInNew } from "@mui/icons-material";

import { QRCodeSVG } from "qrcode.react";

import { CUSTOMER_PORTAL_BASE_URL } from "../../../../utils/config";
import DownloadQR from "../components/downloadQR";

export default function FilterRow() {
  const { restaurant } = useSelector((state) => state.restaurant);

  return (
    <Stack direction="row" justifyContent="flex-start" alignItems="center">
      <Stack direction="row" alignItems="center" spacing={1.5}>
        <Tooltip size="sm" title="Coming Soon.." variant="solid">
          <Button
            variant="outlined"
            color="neutral"
            size="sm"
            startDecorator={<FilterList />}
          >
            <Typography level="body-sm" textColor="neutral.400">
              Filters
            </Typography>
          </Button>
        </Tooltip>
        <QRCodeSVG
          id="qr-code-svg"
          value={`${CUSTOMER_PORTAL_BASE_URL}/${restaurant.uid}`}
          size={290}
          level={"H"}
          includeMargin={true}
          style={{
            display: "none",
          }}
        />
        <DownloadQR>
          <Button
            variant="outlined"
            color="neutral"
            size="sm"
            startDecorator={<Download />}
          >
            Download Menu QR
          </Button>
        </DownloadQR>
        <Tooltip
          size="sm"
          title="The menu that your customers will see"
          variant="solid"
        >
          <Button
            variant="plain"
            color="neutral"
            size="sm"
            endDecorator={
              <sup>
                <OpenInNew
                  sx={{
                    height: "12px",
                  }}
                />
              </sup>
            }
            onClick={() => {
              window.open(
                `${CUSTOMER_PORTAL_BASE_URL}/${restaurant.uid}`,
                "_blank"
              );
            }}
          >
            See Menu
          </Button>
        </Tooltip>
      </Stack>
    </Stack>
  );
}

import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  Modal,
  ModalDialog,
  Dropdown,
  MenuButton,
  Menu,
  MenuItem,
  IconButton,
  Typography,
} from "@mui/joy";

import { Delete, WarningRounded, Undo, MoreVert } from "@mui/icons-material";

import axios from "axios";

import { getAuthorizationHeader } from "../../../../../utils/helpers";
import {
  setItems,
  setMenuEditor,
  setVariants,
} from "../../../../../redux/restaurant";
import { DOMAIN } from "../../../../../utils/config";
import { setNotify } from "../../../../../redux/utils";

export default function MoreSettings({ resetState, item }) {
  const { restaurant, items, variants } = useSelector(
    (state) => state.restaurant
  );

  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const dispatch = useDispatch();

  const handleDelete = () => {
    setDeleting(true);
    axios({
      method: "DELETE",
      url: `${DOMAIN}/menus/items/`,
      headers: getAuthorizationHeader(),
      params: {
        uid: restaurant.uid,
        id: item.id,
      },
    })
      .then((res) => {
        dispatch(
          setMenuEditor({
            currentlyEditing: null,
            entryId: null,
            saving: false,
          })
        );
        dispatch(setVariants(variants.filter((i) => i.item !== item.id)));
        dispatch(setItems(items.filter((i) => i.id !== item.id)));
        setDeleting(false);

        dispatch(
          setNotify({
            open: true,
            action: "Item Deleted!",
            severity: "success",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      })
      .catch((err) => {
        setDeleting(false);

        dispatch(
          setNotify({
            open: true,
            action: "Unable to Delete!",
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      });
  };

  return (
    <>
      <Dropdown>
        <MenuButton
          slots={{ root: IconButton }}
          slotProps={{
            root: { size: "sm" },
          }}
        >
          <MoreVert />
        </MenuButton>
        <Menu
          size="sm"
          placement="bottom-end"
          sx={{
            minWidth: "120px",
          }}
        >
          <MenuItem onClick={resetState}>
            <Undo fontSize="small" />
            Undo
          </MenuItem>
          <MenuItem color="danger" onClick={() => setOpenDeleteConfirm(true)}>
            <Delete fontSize="small" />
            Delete
          </MenuItem>
        </Menu>
      </Dropdown>
      <Modal
        open={openDeleteConfirm}
        onClose={() => setOpenDeleteConfirm(false)}
      >
        <ModalDialog variant="outlined" role="alertdialog" maxWidth="400px">
          <DialogTitle>
            <WarningRounded />
            Confirmation
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Typography level="body-sm">
              Are you sure you want to delete <b>{item.name}</b>?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="solid"
              color="danger"
              size="sm"
              onClick={handleDelete}
              disabled={deleting}
            >
              {deleting ? "Deleting" : "Delete"}
            </Button>
            <Button
              variant="plain"
              color="neutral"
              size="sm"
              onClick={() => setOpenDeleteConfirm(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
}

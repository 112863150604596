import { Stack } from "@mui/joy";

import CreateRestaurant from "./createRestaurant";
import Header from "./header";
import PoweredBy from "../../../components/smartdinein/poweredBy";
import Loading from "../../../hoc/loading";
import { useEffect, useState } from "react";
import axios from "axios";
import { DOMAIN } from "../../../utils/config";
import { getAuthorizationHeader } from "../../../utils/helpers";
import { useDispatch } from "react-redux";
import { setNotify } from "../../../redux/utils";

export default function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    Promise.all([
      axios({
        method: "GET",
        url: `${DOMAIN}/billings/plans/`,
        headers: getAuthorizationHeader(),
      }),
    ])
      .then(([plansRes]) => {
        setLoading(false);
        setPlans(plansRes.data);
      })
      .catch(() => {
        setLoading(false);
        dispatch(
          setNotify({
            open: true,
            action: "Something went wrong!",
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      });
  }, [dispatch]);

  return (
    <Loading loading={loading}>
      <Stack
        sx={{
          minHeight: "100vh",
        }}
        justifyContent="space-between"
      >
        <Stack spacing={2}>
          <Header />
          <CreateRestaurant plans={plans} />
        </Stack>
        <PoweredBy />
      </Stack>
    </Loading>
  );
}

import { createSlice } from "@reduxjs/toolkit";

const defaultMenuEditor = {
  currentlyEditing: null, // null, category, item
  entryId: null, // instance id
  saving: false,
};

export const restaurantSlice = createSlice({
  name: "restaurant",
  initialState: {
    fetchedRestaurants: false,

    accessCategory: {},
    tags: [],

    restaurants: [],
    restaurant: {},

    members: [],
    sessions: [],
    pendingOrders: [],
    orders: [],
    customers: [],

    menu: {},
    itemCategories: [],
    items: [],
    variants: [],

    tableTags: [],
    tables: [],
    rooms: [],

    bookingCodeRequests: [],
    callingTables: [],
    requestingBill: [],
    activeOrders: [],
    fetchedActiveOrders: false,

    menuEditor: defaultMenuEditor,
  },
  reducers: {
    setFetchedRestaurants: (state, { payload }) => {
      state.fetchedRestaurants = payload;
    },

    setAccessCategory: (state, { payload }) => {
      state.accessCategory = payload;
    },
    setTags: (state, { payload }) => {
      state.tags = payload;
    },

    setRestaurants: (state, { payload }) => {
      state.restaurants = payload;
    },
    setRestaurant: (state, { payload }) => {
      state.restaurant = payload;
    },

    setMembers: (state, { payload }) => {
      state.members = payload;
    },

    setPendingOrders: (state, { payload }) => {
      state.pendingOrders = payload;
    },
    addPendingOrder: (state, { payload }) => {
      state.pendingOrders = [...state.pendingOrders, payload];
    },

    setOrders: (state, { payload }) => {
      state.orders = payload;
    },
    addOrder: (state, { payload }) => {
      state.orders = [...state.orders, payload];
    },

    setSessions: (state, { payload }) => {
      state.sessions = payload;
    },
    setCustomers: (state, { payload }) => {
      state.customers = payload;
    },

    addNewSessionAndCustomer: (state, { payload }) => {
      const { customer_details, ...rest } = payload;
      state.sessions = [...state.sessions, rest];
      state.customers = [...state.customers, customer_details];
    },

    setMenu: (state, { payload }) => {
      state.menu = payload;
    },
    setItemCategories: (state, { payload }) => {
      state.itemCategories = payload;
    },
    setItems: (state, { payload }) => {
      state.items = payload;
    },
    setVariants: (state, { payload }) => {
      state.variants = payload;
    },

    setTableTags: (state, { payload }) => {
      state.tableTags = payload;
    },
    setTables: (state, { payload }) => {
      state.tables = payload;
    },
    setRooms: (state, { payload }) => {
      state.rooms = payload;
    },

    setBookingCodeRequests: (state, { payload }) => {
      state.bookingCodeRequests = payload;
    },
    addBookingCodeRequests: (state, { payload }) => {
      state.bookingCodeRequests = [...state.bookingCodeRequests, payload];
    },
    removeBookingCodeRequests: (state, { payload }) => {
      state.bookingCodeRequests = state.bookingCodeRequests.filter(
        (i) => i.table_id !== payload.table_id
      );
    },

    setCallingTables: (state, { payload }) => {
      state.callingTables = payload;
    },
    addCallingTables: (state, { payload }) => {
      state.callingTables = [...state.callingTables, payload];
    },
    removeCallingTables: (state, { payload }) => {
      state.callingTables = state.callingTables.filter((i) => i !== payload);
    },

    setRequestingBill: (state, { payload }) => {
      state.requestingBill = payload;
    },
    addRequestingBill: (state, { payload }) => {
      state.requestingBill = [...state.requestingBill, payload];
    },
    removeRequestingBill: (state, { payload }) => {
      state.requestingBill = state.requestingBill.filter((i) => i !== payload);
    },

    setActiveOrders: (state, { payload }) => {
      state.activeOrders = payload;
    },
    addActiveOrders: (state, { payload }) => {
      state.activeOrders = [...state.activeOrders, payload];
    },
    removeActiveOrders: (state, { payload }) => {
      state.activeOrders = state.activeOrders.filter((i) => i !== payload);
    },
    setFetchedActiveOrders: (state, { payload }) => {
      state.fetchedActiveOrders = payload;
    },

    setMenuEditor: (state, { payload }) => {
      state.menuEditor = payload;
    },
    resetMenuEditor: (state) => {
      state.menuEditor = defaultMenuEditor;
    },
  },
});

export const {
  setFetchedRestaurants,

  setAccessCategory,
  setTags,

  setRestaurants,
  setRestaurant,
  setMembers,

  setPendingOrders,
  addPendingOrder,

  setOrders,
  addOrder,

  setSessions,
  setCustomers,
  addNewSessionAndCustomer,

  setMenu,
  setItemCategories,
  setItems,
  setVariants,

  setTables,
  setTableTags,
  setRooms,

  setBookingCodeRequests,
  addBookingCodeRequests,
  removeBookingCodeRequests,

  setCallingTables,
  addCallingTables,
  removeCallingTables,

  setRequestingBill,
  addRequestingBill,
  removeRequestingBill,

  setActiveOrders,
  addActiveOrders,
  removeActiveOrders,
  setFetchedActiveOrders,

  setMenuEditor,
  resetMenuEditor,
} = restaurantSlice.actions;

export default restaurantSlice.reducer;

import { Stack, Typography, Grid } from "@mui/joy";

import Available from "./available";

export default function AvailableList({ isRoom, availableTableList }) {
  return (
    <Stack spacing={1}>
      <Typography level="title-lg">
        <b>Available</b> ({availableTableList.length})
      </Typography>
      <Grid container rowSpacing={2} columnSpacing={3}>
        {availableTableList.length ? (
          availableTableList.map((table, index) => (
            <Available table={table} isRoom={isRoom} key={index} />
          ))
        ) : (
          <Grid xs={12}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                minHeight: "300px",
              }}
            >
              <Typography level="body-sm" textColor="neutral.400">
                No {isRoom ? "Rooms" : "Tables"} Added!
              </Typography>
            </Stack>
          </Grid>
        )}
      </Grid>
    </Stack>
  );
}

import { useState, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";

import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Sheet,
  Stack,
  Textarea,
  Typography,
} from "@mui/joy";

import axios from "axios";
import { DOMAIN } from "../../../../../utils/config";
import { getAuthorization } from "../../../../../utils/helpers";
import { setNotify } from "../../../../../redux/utils";
import { setRestaurant, setRestaurants } from "../../../../../redux/restaurant";
import DeleteRestaurant from "./deleteRestaurant";

import logo_placeholder from "../../../../../assets/images/restaurant.png";

const DEFAULT_IMAGE = logo_placeholder;

export default function UpdateRestaurantDetails() {
  const selectedImageRef = useRef(null);

  const { restaurants, restaurant } = useSelector((state) => state.restaurant);

  const getRestaurantDefaultLogo = () => {
    if (restaurant.logo) {
      return restaurant.logo;
    }
    return DEFAULT_IMAGE;
  };

  const getRestaurantLogo = () => {
    if (selectedImage) {
      if (typeof selectedImage === "string") return selectedImage;
      return URL.createObjectURL(selectedImage);
    }
    return getRestaurantDefaultLogo();
  };

  const [name, setName] = useState(restaurant.name || "");
  const [description, setDescription] = useState(restaurant.description || "");
  const [googleMapsUri, setGoogleMapsUri] = useState(
    restaurant.google_maps_uri || ""
  );
  const [selectedImage, setSelectedImage] = useState(
    getRestaurantDefaultLogo()
  );
  const [saving, setSaving] = useState(false);

  const dispatch = useDispatch();

  const isInfoChanged = () => {
    if (
      name !== restaurant.name ||
      description !== restaurant.description ||
      googleMapsUri !== restaurant.google_maps_uri ||
      getRestaurantDefaultLogo() !== getRestaurantLogo()
    )
      return true;
    return false;
  };

  const handleSave = () => {
    const payload = new FormData();

    if (name !== restaurant.name) {
      payload.append("name", name);
    }

    if (description !== restaurant.description) {
      payload.append("description", description);
    }

    if (googleMapsUri !== restaurant.google_maps_uri) {
      payload.append("google_maps_uri", googleMapsUri);
    }

    if (getRestaurantDefaultLogo() !== getRestaurantLogo()) {
      payload.append("logo", selectedImage);
    }

    setSaving(true);
    axios({
      method: "PATCH",
      url: `${DOMAIN}/organizations/`,
      headers: {
        Authorization: getAuthorization(),
        "Content-Type": "multipart/form-data",
      },
      params: {
        uid: restaurant.uid,
      },
      data: payload,
    })
      .then((res) => {
        setSaving(false);
        dispatch(setRestaurant(res.data));
        dispatch(
          setRestaurants(
            restaurants.map((i) => {
              if (i === restaurant.id) return res.data;
              return i;
            })
          )
        );
        dispatch(
          setNotify({
            open: true,
            action: "Changes Saved!",
            severity: "success",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      })
      .catch((err) => {
        setSaving(false);
        let errorMessage = "Something went Wrong!";
        try {
          errorMessage = JSON.parse(err.request.response).message;
        } catch (error) {}
        dispatch(
          setNotify({
            open: true,
            action: errorMessage,
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      });
  };

  return (
    <Box mt={"0 !important"} boxShadow="sm" borderRadius="6px">
      <Sheet
        sx={{
          bgcolor: "#ffffff",
          padding: "20px 20px",
          borderRadius: "6px",
        }}
      >
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="center">
            <Avatar
              src={getRestaurantLogo()}
              sx={{
                height: "120px",
                width: "120px",
                cursor: "pointer",
                border: "1px solid #f5f5f5",
              }}
              onClick={() => {
                selectedImageRef.current.click();
              }}
            ></Avatar>
            <input
              hidden
              type="file"
              accept="image/png, image/jpg"
              ref={selectedImageRef}
              onChange={(e) => {
                setSelectedImage(e.target.files[0]);
              }}
            />
          </Stack>
          <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography level="title-md">Basic Information</Typography>
              <Button
                disabled={!isInfoChanged() || saving}
                size="sm"
                color="neutral"
                onClick={handleSave}
              >
                {saving ? "Saving" : "Save"}
              </Button>
            </Stack>
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Restaurant Name</FormLabel>
                <Input
                  required
                  size="sm"
                  type="text"
                  placeholder="Write your Restaurant name here"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Description</FormLabel>
                <Textarea
                  size="sm"
                  minRows={2}
                  maxRows={5}
                  placeholder="Write something about your Restaurant."
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Google Maps Link</FormLabel>
                <Input
                  size="sm"
                  type="text"
                  placeholder="eg. https://maps.app.goo.gl/Qt8ZgAsQexxxxxxxx"
                  value={googleMapsUri}
                  onChange={(e) => setGoogleMapsUri(e.target.value)}
                />
              </FormControl>
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <Typography level="title-md">Danger Zone</Typography>
            <Stack direction="row" justifyContent="space-between">
              <DeleteRestaurant />
            </Stack>
            <Typography level="body-xs">
              <b>NOTE:</b> Once Deleted, this action can't be undone.
            </Typography>
          </Stack>
        </Stack>
      </Sheet>
    </Box>
  );
}

import { useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { useParams } from "react-router-dom";

import { Stack, Button, Modal, Typography, Sheet } from "@mui/joy";

import { DoneAll } from "@mui/icons-material";

import { getAuthorizationHeader } from "../../../../../utils/helpers";
import { DOMAIN } from "../../../../../utils/config";
import { setNotify } from "../../../../../redux/utils";

import axios from "axios";
import { setOrders } from "../../../../../redux/restaurant";

export default function ServeOrder({ order, orderSeq, table }) {
  const { orders } = useSelector((state) => state.restaurant);

  const [open, setOpen] = useState(false);
  const [sending, setSending] = useState(false);

  const { restaurantId } = useParams();

  const dispatch = useDispatch();

  const handleRequest = () => {
    setSending(true);
    axios({
      method: "POST",
      url: `${DOMAIN}/orders/served/`,
      headers: getAuthorizationHeader(),
      params: {
        organization_uid: restaurantId,
      },
      data: {
        order_id: order.id,
      },
    })
      .then((res) => {
        dispatch(setOrders([...orders, res.data]));

        setSending(false);
        setOpen(false);
        dispatch(
          setNotify({
            open: true,
            action: "Saved!",
            severity: "success",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "center",
          })
        );
      })
      .catch((err) => {
        setSending(false);
        setOpen(false);
        let errorMessage = "Something went Wrong!";
        try {
          errorMessage = JSON.parse(err.request.response).message;
        } catch (error) {}
        dispatch(
          setNotify({
            open: true,
            action: errorMessage,
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "center",
          })
        );
      });
  };

  return (
    <>
      <Button
        fullWidth
        startDecorator={<DoneAll />}
        variant="solid"
        color="success"
        size="sm"
        sx={{
          display: order.served_on ? "none" : "flex",
        }}
        disabled={sending}
        onClick={() => setOpen(true)}
      >
        Served
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{
            minWidth: "300px",
            borderRadius: "md",
            p: 3,
          }}
        >
          <Stack spacing={3}>
            <Stack spacing={1}>
              <Typography level="title-sm">
                Order <b>#{orderSeq}</b> Served to <b>{table.name}</b>?
              </Typography>
              <Typography level="body-xs" textColor="neutral.400">
                Have you served <b>Order no. #{orderSeq}</b> to{" "}
                <b>{table.name}</b>?
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1.5}
            >
              <Button
                fullWidth
                color="neutral"
                variant="outlined"
                disabled={sending}
                size="sm"
                onClick={() => setOpen(false)}
              >
                No
              </Button>
              <Button
                fullWidth
                color="neutral"
                disabled={sending}
                size="sm"
                onClick={handleRequest}
              >
                Yes
              </Button>
            </Stack>
          </Stack>
        </Sheet>
      </Modal>
    </>
  );
}

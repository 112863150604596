import { useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Stack, Grid, Typography, IconButton } from "@mui/joy";

import { ChevronLeft, KeyboardArrowDown } from "@mui/icons-material";

import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCorners,
  useSensor,
  useSensors,
} from "@dnd-kit/core";

import {
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";

import ItemCategory from "./itemCategory";
import axios from "axios";
import { DOMAIN } from "../../../../../utils/config";
import { getAuthorizationHeader } from "../../../../../utils/helpers";
import { setItemCategories } from "../../../../../redux/restaurant";
import { setNotify } from "../../../../../redux/utils";

export default function CategoriesSection() {
  const { restaurant, itemCategories } = useSelector(
    (state) => state.restaurant
  );

  const [collapsed, setCollapsed] = useState(false);

  const dispatch = useDispatch();

  const handleDragEnd = (event) => {
    let backup = itemCategories.map((i) => i);

    const { active, over } = event;

    const activeInstance = itemCategories.filter(
      (ic) => ic.seq_no === active.id
    )[0];
    const overInstance = itemCategories.filter(
      (ic) => ic.seq_no === over.id
    )[0];

    const seqNoUpgraded = active.id < over.id;
    const operation = seqNoUpgraded ? -1 : 1;

    let updatedArray = [];
    if (seqNoUpgraded) {
      updatedArray = itemCategories.map((ic) => {
        if (ic.id === activeInstance.id) {
          return {
            ...ic,
            seq_no: over.id,
          };
        } else if ((ic.seq_no > active.id) & (ic.seq_no <= over.id)) {
          return {
            ...ic,
            seq_no: ic.seq_no + operation,
          };
        }
        return ic;
      });
    } else {
      updatedArray = itemCategories.map((ic) => {
        if (ic.id === activeInstance.id) {
          return {
            ...ic,
            seq_no: over.id,
          };
        } else if ((ic.seq_no >= over.id) & (ic.seq_no < active.id)) {
          return {
            ...ic,
            seq_no: ic.seq_no + operation,
          };
        }
        return ic;
      });
    }
    dispatch(setItemCategories(updatedArray));
    axios({
      method: "POST",
      url: `${DOMAIN}/menus/item-categories/rearrange/`,
      headers: getAuthorizationHeader(),
      params: {
        uid: restaurant.uid,
      },
      data: {
        active_id: activeInstance.id,
        over_id: overInstance.id,
      },
    })
      .then((res) =>
        dispatch(
          setNotify({
            open: true,
            action: "Saved!",
            severity: "success",
            autoHideDuration: 2000,
            vertical: "bottom",
            horizontal: "right",
          })
        )
      )
      .catch((err) => {
        dispatch(setItemCategories(backup));
      });
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const getSeqWiseItemCategories = () => {
    let itemCategoriesCopy = itemCategories.map((i) => i);
    return itemCategoriesCopy.sort((a, b) => a.seq_no - b.seq_no);
  };

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography level="title-lg">
          <b>Categories</b>{" "}
          {itemCategories.length ? `(${itemCategories.length})` : null}
        </Typography>
        <IconButton
          size="sm"
          color="neutral"
          variant="outlined"
          onClick={() => setCollapsed(!collapsed)}
        >
          {collapsed ? <ChevronLeft /> : <KeyboardArrowDown />}
        </IconButton>
      </Stack>
      <DndContext
        onDragEnd={handleDragEnd}
        sensors={sensors}
        collisionDetection={closestCorners}
      >
        <Grid container display={collapsed ? "none" : "flex"}>
          <SortableContext
            items={getSeqWiseItemCategories().map((i) => i.seq_no)}
          >
            {getSeqWiseItemCategories().length ? (
              getSeqWiseItemCategories().map((itemCategory, index) => (
                <ItemCategory
                  itemCategory={itemCategory}
                  key={index}
                  index={index}
                />
              ))
            ) : (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                minHeight="100px"
                width="100%"
              >
                <Typography level="body-xs" textAlign="center">
                  Add a Category (eg. Veg Pizzas)
                </Typography>
              </Stack>
            )}
          </SortableContext>
        </Grid>
      </DndContext>
    </Stack>
  );
}

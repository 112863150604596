import { useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { setRooms, setTables } from "../../../../../redux/restaurant";
import { setNotify } from "../../../../../redux/utils";

import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalDialog,
  DialogTitle,
  DialogContent,
  Stack,
  IconButton,
  Divider,
  Grid,
  Typography,
} from "@mui/joy";

import { Add, Check, Close, Delete } from "@mui/icons-material";

import axios from "axios";

import { DOMAIN } from "../../../../../utils/config";
import {
  getAuthorizationHeader,
  getTagIcon,
} from "../../../../../utils/helpers";

export default function AddTables({ isRoom }) {
  const typeName = isRoom ? "Room" : "Table";

  const { restaurant, tables, rooms, tableTags } = useSelector(
    (state) => state.restaurant
  );

  const [open, setOpen] = useState(false);

  const [capacity, setCapacity] = useState("");
  const [nameList, setNameList] = useState([
    {
      id: Math.floor(Math.random() * 10000000),
      value: "",
    },
  ]);
  const [saving, setSaving] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);

  const dispatch = useDispatch();

  const handleSave = (e) => {
    e.preventDefault();
    setSaving(true);
    const payload = {
      names: nameList.map((i) => i.value),
      capacity,
      tags: isRoom ? [] : selectedTags.map((i) => i.id),
      type: isRoom ? "room" : "table",
    };
    axios({
      method: "POST",
      url: `${DOMAIN}/tables/`,
      headers: getAuthorizationHeader(),
      params: {
        organization_uid: restaurant.uid,
      },
      data: payload,
    })
      .then((res) => {
        if (isRoom) dispatch(setRooms([...rooms, ...res.data]));
        else dispatch(setTables([...tables, ...res.data]));
        setSaving(false);
        setSelectedTags([]);
        setCapacity(null);
        setNameList([
          {
            id: Math.floor(Math.random() * 10000000),
            value: "",
          },
        ]);
        dispatch(
          setNotify({
            open: true,
            action: `${typeName}(s) Added`,
            severity: "success",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
        setOpen(false);
      })
      .catch((err) => {
        setSaving(false);
        let errorMessage = "Something went Wrong!";
        try {
          errorMessage = JSON.parse(err.request.response).message;
        } catch (error) {}
        dispatch(
          setNotify({
            open: true,
            action: errorMessage,
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      });
  };

  const getUnselectedTags = () => {
    return tableTags.filter((t) => !selectedTags.includes(t));
  };

  const handleUnselectTag = (tag) => {
    setSelectedTags(selectedTags.filter((t) => t.id !== tag.id));
  };

  const handleSelectTag = (tag) => {
    setSelectedTags([...selectedTags, tag]);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="neutral"
        size="sm"
        startDecorator={<Add />}
        onClick={() => setOpen(true)}
      >
        Add {typeName}s
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog minWidth="450px">
          <DialogTitle>
            <Typography level="title-lg">Add New {typeName}s</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography level="body-sm" textColor="neutral.400">
              Fill in the information of the {typeName}s.
            </Typography>
          </DialogContent>
          <form onSubmit={handleSave}>
            <Stack spacing={2} maxHeight="80vh" overflow="scroll">
              <Stack spacing={1}>
                <FormLabel>{typeName} Title</FormLabel>
                {nameList.map((name, index) => (
                  <Stack key={index} direction="row" spacing={2}>
                    <FormControl sx={{ width: "100%" }}>
                      <Input
                        fullWidth
                        size="sm"
                        autoFocus
                        required
                        placeholder={`Eg. ${typeName} 1A`}
                        value={name.value}
                        onChange={(e) => {
                          setNameList(
                            nameList.map((i) => {
                              if (i.id === name.id) {
                                return {
                                  id: i.id,
                                  value: e.target.value,
                                };
                              } else return i;
                            })
                          );
                        }}
                      />
                    </FormControl>
                    <IconButton
                      size="sm"
                      color="danger"
                      disabled={nameList.length < 2}
                      onClick={() => {
                        setNameList(nameList.filter((i) => i.id !== name.id));
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Stack>
                ))}
              </Stack>
              <Button
                color="neutral"
                size="sm"
                variant="outlined"
                startDecorator={<Add />}
                onClick={() => {
                  setNameList([
                    ...nameList,
                    {
                      id: Math.floor(Math.random() * 10000000),
                      value: "",
                    },
                  ]);
                }}
              >
                Add More {typeName}
              </Button>
              <Divider orientation="horizontal" />
              <FormControl>
                <FormLabel>Capacity</FormLabel>
                <Input
                  fullWidth
                  size="sm"
                  required
                  type="number"
                  placeholder="Eg. 4"
                  value={capacity}
                  onChange={(e) => setCapacity(e.target.value)}
                />
              </FormControl>
              {!isRoom && (
                <>
                  <Stack spacing={2}>
                    <Grid container>
                      <Grid xs={6}>
                        <Stack spacing={1}>
                          <Typography level="title-sm">
                            <b>Selected Tags</b> ({selectedTags.length})
                          </Typography>
                          {selectedTags.length ? (
                            selectedTags.map((tag, index) => (
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                key={index}
                              >
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={1}
                                >
                                  {getTagIcon(tag)}
                                  <Typography level="body-xs">
                                    {tag.name}
                                  </Typography>
                                </Stack>
                                <IconButton
                                  size="sm"
                                  onClick={() => handleUnselectTag(tag)}
                                >
                                  <Close />
                                </IconButton>
                              </Stack>
                            ))
                          ) : (
                            <Stack
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                              minHeight="200px"
                            >
                              <Typography level="body-xs">
                                No Tags Selected
                              </Typography>
                            </Stack>
                          )}
                        </Stack>
                      </Grid>
                      <Grid xs={6} paddingLeft={2} minHeight="250px">
                        <Stack spacing={1}>
                          <Typography level="title-sm">
                            <b>Available Tags</b>
                          </Typography>
                          {getUnselectedTags().length ? (
                            getUnselectedTags().map((tag, index) => (
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                key={index}
                              >
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={1}
                                >
                                  {getTagIcon(tag)}
                                  <Typography level="body-xs">
                                    {tag.name}
                                  </Typography>
                                </Stack>
                                <IconButton
                                  size="sm"
                                  onClick={() => handleSelectTag(tag)}
                                >
                                  <Check />
                                </IconButton>
                              </Stack>
                            ))
                          ) : (
                            <Stack
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                              minHeight="200px"
                            >
                              <Typography level="body-xs">
                                All Tags Selected
                              </Typography>
                            </Stack>
                          )}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Stack>
                </>
              )}
              <Button
                type="submit"
                color="neutral"
                size="sm"
                disabled={saving || !capacity}
              >
                {saving ? "Creating" : "Create"}
              </Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </>
  );
}

import { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { setActivePathId } from "../../../../redux/utils";

import Loading from "../../../../hoc/loading";

import BodyWrapper from "../components/bodyWrapper";
import FilterRow from "./filterRow";
import Pending from "./pending";
import Served from "./served";
import Billed from "./billed";

import urls from "../../../../utils/urls.json";
import { isMobile } from "../../../../utils/config";

export default function Orders() {
  const { fetchedActiveOrders, pendingOrders, orders } = useSelector(
    (state) => state.restaurant
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActivePathId(urls.restaurant.children.orders.pathId));
  }, [dispatch]);

  return (
    <BodyWrapper withStack>
      {!isMobile() && <FilterRow />}
      <Loading loading={!fetchedActiveOrders}>
        <Pending
          pendingOrders={pendingOrders
            .filter((i) => i.placed_on && !i.served_on)
            .sort((a, b) => new Date(a.placed_on) - new Date(b.placed_on))}
        />
        <Served
          servedOrders={orders
            .filter((i) => i.placed_on && i.served_on && !i.completed_on)
            .sort((a, b) => new Date(b.served_on) - new Date(a.served_on))}
        />
        <Billed
          billedOrders={orders
            .filter((i) => i.placed_on && i.served_on && i.completed_on)
            .sort(
              (a, b) => new Date(b.completed_on) - new Date(a.completed_on)
            )}
        />
      </Loading>
    </BodyWrapper>
  );
}

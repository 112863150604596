import { useState } from "react";

import { useDispatch } from "react-redux";
import {
  setFetchedRestaurants,
  setRestaurant,
  setRestaurants,
} from "../../../redux/restaurant";

import {
  Button,
  Card,
  Checkbox,
  Container,
  FormControl,
  FormHelperText,
  Input,
  Option,
  Select,
  Stack,
  Typography,
} from "@mui/joy";

import axios from "axios";
import {
  DOMAIN,
  getCurrency,
  TERMS_AND_CONDITIONS_PAGE,
} from "../../../utils/config";
import { getAuthorizationHeader } from "../../../utils/helpers";
import { setNotify } from "../../../redux/utils";

export default function CreateOrganization({ plans }) {
  const [name, setName] = useState("");
  const [currency, setCurrency] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("");
  const [saving, setSaving] = useState(false);

  const [checked, setChecked] = useState(false);

  const [showReferral, setShowReferral] = useState(true);
  const [haveReferral, setHaveReferral] = useState(false);
  const [referralCode, setReferralCode] = useState("");

  const dispatch = useDispatch();

  const create = () => {
    setSaving(true);
    axios({
      method: "POST",
      url: `${DOMAIN}/organizations/`,
      headers: getAuthorizationHeader(),
      data: {
        name,
        currency,
        referral_code: referralCode,
        plan_id: selectedPlan,
      },
    })
      .then((res) => {
        dispatch(setRestaurants([res.data]));
        dispatch(setFetchedRestaurants(true));
        dispatch(setRestaurant(res.data));
        window.location.replace(`/${res.data.uid}`);
      })
      .catch((err) => {
        setSaving(false);
        const errorObj = JSON.parse(err.request.response);
        // notify
        dispatch(
          setNotify({
            open: true,
            action: errorObj.message || "Something went wrong!",
            severity: "danger",
            autoHideDuration: 5000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      });
  };

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "70vh",
      }}
    >
      <Container maxWidth="sm">
        <Stack boxShadow="lg" borderRadius={6}>
          <Card
            size="lg"
            sx={{
              border: "none",
            }}
          >
            <Stack spacing={3}>
              <Stack justifyContent="center" alignItems="center" spacing={2}>
                <Typography level="h4" component="h1">
                  Add Restaurant
                </Typography>
                <Typography
                  level="body-sm"
                  textColor="neutral.500"
                  textAlign="center"
                >
                  Please fill in the details of your restaurant to get started
                  with Smart Dine-in. Accurate information ensures a seamless
                  and efficient experience for you and your customers.
                </Typography>
              </Stack>
              <Stack justifyContent="center" alignItems="center" spacing={2}>
                <Input
                  fullWidth
                  size="lg"
                  variant="outlined"
                  placeholder="Enter Your Restaurant Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></Input>
                <Stack direction="row" spacing={2} width="100%">
                  <Select
                    placeholder="Choose your Currency"
                    sx={{
                      width: "100%",
                    }}
                    value={currency}
                    onChange={(_, newValue) => {
                      if (newValue === "USD") {
                        setReferralCode("");
                        setHaveReferral(false);
                        setShowReferral(false);
                      } else {
                        setShowReferral(true);
                      }
                      setCurrency(newValue);
                    }}
                  >
                    <Option value="INR">₹ (INR)</Option>
                    <Option value="USD">$ (USD)</Option>
                  </Select>
                  <Select
                    disabled={!currency}
                    placeholder="Choose a Plan"
                    sx={{
                      width: "100%",
                    }}
                    value={selectedPlan}
                    onChange={(_, newValue) => {
                      setSelectedPlan(newValue);
                    }}
                  >
                    {plans
                      .filter((i) => i.razor_item_currency === currency)
                      .sort((a, b) => a.id - b.id)
                      .map((plan, index) => (
                        <Option
                          key={index}
                          value={plan.id}
                          disabled={!plan.is_live}
                        >
                          {plan.razor_item_name} ({getCurrency(currency)}
                          {plan.razor_item_amount / 100} {plan.razor_period})
                        </Option>
                      ))}
                  </Select>
                </Stack>
                {showReferral && (
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1}
                    width="100%"
                  >
                    <Checkbox
                      size="sm"
                      checked={haveReferral}
                      onChange={() => {
                        setHaveReferral(!haveReferral);
                        setReferralCode("");
                      }}
                    />
                    <Typography
                      level="body-xs"
                      textColor="neutral.500"
                      textAlign="center"
                    >
                      Have a Referral Code?
                    </Typography>
                  </Stack>
                )}
                {showReferral && haveReferral && (
                  <FormControl>
                    <Input
                      size="sm"
                      variant="soft"
                      placeholder="Enter Referral Code"
                      value={referralCode}
                      onChange={(e) => setReferralCode(e.target.value)}
                    />
                    <FormHelperText>
                      <Typography level="body-xs" textColor="neutral.400">
                        Referral code can't be changed later
                      </Typography>
                    </FormHelperText>
                  </FormControl>
                )}
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                  width="100%"
                >
                  <Checkbox
                    size="sm"
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                  />
                  <Typography
                    level="body-xs"
                    textColor="neutral.500"
                    textAlign="center"
                  >
                    Accept Smart Dine-in's{" "}
                    <a
                      href={TERMS_AND_CONDITIONS_PAGE}
                      rel="noreferrer"
                      target="_blank"
                      style={{
                        color: "inherit",
                        textDecoration: "underline",
                      }}
                    >
                      Terms and Conditions
                    </a>
                  </Typography>
                </Stack>
                <Button
                  disabled={
                    !checked ||
                    saving ||
                    !name.length ||
                    !selectedPlan ||
                    (haveReferral && !referralCode.length)
                  }
                  fullWidth
                  size="lg"
                  color="neutral"
                  onClick={create}
                >
                  {saving ? "Adding" : "Continue"}
                </Button>
              </Stack>
            </Stack>
          </Card>
        </Stack>
      </Container>
    </Stack>
  );
}

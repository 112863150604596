import { useSelector, useDispatch } from "react-redux";
import {
  setItemCategories,
  setMenuEditor,
} from "../../../../../redux/restaurant";

import { Stack, Grid, Typography, Switch, IconButton, Sheet } from "@mui/joy";

import { Menu as MenuIcon, DriveFileRenameOutline } from "@mui/icons-material";

import { useSortable } from "@dnd-kit/sortable";

import axios from "axios";

import { DOMAIN } from "../../../../../utils/config";
import { getAuthorizationHeader } from "../../../../../utils/helpers";
import { setNotify } from "../../../../../redux/utils";

export default function ItemCategory({ itemCategory, index }) {
  const { attributes, listeners, setNodeRef, transform } = useSortable({
    id: itemCategory.seq_no,
  });

  const { restaurant, itemCategories, items, menuEditor } = useSelector(
    (state) => state.restaurant
  );

  const getItemCount = (itemCategory) => {
    return items.filter((i) => i.category === itemCategory.id).length;
  };

  const getLiveItemCount = (itemCategory) => {
    return items.filter((i) => i.category === itemCategory.id && i.is_live)
      .length;
  };

  const dispatch = useDispatch();

  const setCurrentlyEditingCategory = (category) => {
    dispatch(
      setMenuEditor({
        currentlyEditing: "category",
        entryId: category.id,
        saving: false,
      })
    );
  };

  const updateItemCategoryStockStatus = (category) => {
    axios({
      method: "PATCH",
      url: `${DOMAIN}/menus/item-categories/`,
      headers: getAuthorizationHeader(),
      params: {
        uid: restaurant.uid,
        id: category.id,
      },
      data: {
        is_live: !category.is_live,
      },
    })
      .then((res) => {
        dispatch(
          setItemCategories(
            itemCategories.map((ic) => {
              if (ic.id === category.id) {
                return {
                  ...ic,
                  is_live: res.data.is_live,
                };
              }
              return ic;
            })
          )
        );
        dispatch(
          setNotify({
            open: true,
            action: "Status Changed!",
            severity: "success",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      })
      .catch((err) => {
        let errorMessage = "Something went Wrong!";
        try {
          errorMessage = JSON.parse(err.request.response).message;
        } catch (error) {}
        dispatch(
          setNotify({
            open: true,
            action: errorMessage,
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      });
  };

  const showItemsCount = (itemCategory) => {
    const liveCount = getLiveItemCount(itemCategory);
    const count = getItemCount(itemCategory);

    if (liveCount === count) {
      return count;
    } else {
      return `${liveCount}/${count}`;
    }
  };

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  return (
    <Grid
      xs={6}
      sx={{
        paddingLeft: index % 2 === 0 ? "none" : "10px",
        paddingRight: index % 2 === 0 ? "10px" : "none",
        paddingBottom: "15px",
      }}
      style={style}
      {...attributes}
    >
      <Sheet
        sx={{
          backgroundColor: "#fff",
          borderRadius: "6px",
        }}
      >
        <Stack
          spacing={1.5}
          borderRadius="6px"
          border={`1px solid ${
            menuEditor.currentlyEditing === "category" &&
            menuEditor.entryId === itemCategory.id
              ? "#333333"
              : "#EEEEEE"
          }`}
          boxShadow={
            menuEditor.currentlyEditing === "category" &&
            menuEditor.entryId === itemCategory.id
              ? "lg"
              : "none"
          }
          px={1}
          py={1}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <MenuIcon
                fontSize="small"
                ref={setNodeRef}
                {...listeners}
                sx={{
                  cursor: "grab",
                }}
              />
              <Typography
                level="title-md"
                fontWeight={700}
                onClick={() => setCurrentlyEditingCategory(itemCategory)}
                sx={{
                  cursor: "pointer",
                }}
              >
                {itemCategory.name}
              </Typography>
            </Stack>
            <Stack spacing={1} direction="row">
              <Switch
                size="sm"
                color="neutral"
                checked={itemCategory.is_live}
                variant={itemCategory.is_live ? "solid" : "outlined"}
                onChange={() => updateItemCategoryStockStatus(itemCategory)}
              />
              <IconButton
                size="sm"
                onClick={() => setCurrentlyEditingCategory(itemCategory)}
              >
                <DriveFileRenameOutline />
              </IconButton>
            </Stack>
          </Stack>
          <Grid container>
            <Grid xs={7}>
              <Stack direction="row" spacing={1} pl={1}>
                <Typography level="body-sm">Seq no:</Typography>
                <Typography level="title-sm">
                  <b>{index + 1}</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid xs={5}>
              <Stack direction="row" spacing={1}>
                <Typography level="body-sm">Items:</Typography>
                <Typography level="title-sm" fontWeight={700}>
                  {showItemsCount(itemCategory)}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Sheet>
    </Grid>
  );
}

import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  Modal,
  ModalDialog,
  Dropdown,
  MenuButton,
  Menu,
  MenuItem,
  IconButton,
  Typography,
} from "@mui/joy";

import { WarningRounded, MoreVert, PersonOff } from "@mui/icons-material";

import { getAuthorizationHeader } from "../../../../../../utils/helpers";
import { DOMAIN } from "../../../../../../utils/config";

import axios from "axios";
import { setNotify } from "../../../../../../redux/utils";

export default function MoreSettings({ session, table }) {
  const { restaurant } = useSelector((state) => state.restaurant);

  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const dispatch = useDispatch();

  const handleDelete = () => {
    setDeleting(true);
    axios({
      method: "DELETE",
      url: `${DOMAIN}/orders/sessions/`,
      headers: getAuthorizationHeader(),
      params: {
        organization_uid: restaurant.uid,
        session_id: session.id,
      },
    })
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        setDeleting(false);

        dispatch(
          setNotify({
            open: true,
            action: "Unable to Delete!",
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      });
  };

  return (
    <>
      <Dropdown>
        <MenuButton
          slots={{ root: IconButton }}
          slotProps={{
            root: { size: "sm" },
          }}
        >
          <MoreVert />
        </MenuButton>
        <Menu
          size="sm"
          placement="bottom-end"
          sx={{
            minWidth: "120px",
          }}
        >
          <MenuItem color="danger" onClick={() => setOpenDeleteConfirm(true)}>
            <PersonOff fontSize="small" />
            Kick Customer
          </MenuItem>
        </Menu>
      </Dropdown>
      <Modal
        open={openDeleteConfirm}
        onClose={() => setOpenDeleteConfirm(false)}
      >
        <ModalDialog variant="outlined" role="alertdialog" maxWidth="400px">
          <DialogTitle>
            <WarningRounded />
            Confirmation
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Typography level="body-sm">
              Are you sure you want to kick the customer and mark the table as
              available?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="solid"
              color="danger"
              size="sm"
              onClick={handleDelete}
              disabled={deleting}
            >
              Yes
            </Button>
            <Button
              variant="plain"
              color="neutral"
              size="sm"
              onClick={() => setOpenDeleteConfirm(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
}

import { useSelector } from "react-redux";

import { Box, Sheet, Table, Typography } from "@mui/joy";

import Member from "./member";

export default function TeamMembers() {
  const { members } = useSelector((state) => state.restaurant);

  return (
    <Box mt={"0 !important"} boxShadow="sm" borderRadius="6px">
      <Sheet
        sx={{
          bgcolor: "#ffffff",
          borderRadius: "6px",
          padding: "10px 20px",
        }}
      >
        <Table>
          <thead>
            <tr>
              <th colSpan={3}>Name</th>
              <th colSpan={2}>Group</th>
              <th colSpan={4}>Email</th>
              <th colSpan={3}>Extra Permissions</th>
              <th colSpan={2}>Blocked</th>
              <th colSpan={1}></th>
            </tr>
          </thead>
          <tbody>
            {members.length ? (
              members.map((member, index) => (
                <Member key={index} member={member} />
              ))
            ) : (
              <tr>
                <td colSpan={13}>
                  <Typography level="body-xs" textAlign="center" py={5}>
                    You don't have Permissions to view Team Members
                  </Typography>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Sheet>
    </Box>
  );
}

import { useCallback, useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { authenticate, deAuthenticate } from "../../redux/auth";
import {
  setAccessCategory,
  setRestaurants,
  setRestaurant,
  setFetchedRestaurants,
  setMembers,
  setMenu,
  setItemCategories,
  setItems,
  setVariants,
  setTags,
  setTableTags,
  setTables,
  setRooms,
  addBookingCodeRequests,
  removeBookingCodeRequests,
  setCustomers,
  setSessions,
  removeCallingTables,
  addRequestingBill,
  removeRequestingBill,
  addCallingTables,
  addNewSessionAndCustomer,
  setPendingOrders,
  setActiveOrders,
  setFetchedActiveOrders,
  setOrders,
} from "../../redux/restaurant";

import { useNavigate } from "react-router-dom";

import {
  breakMenusResponse,
  deleteUserCredentials,
  getAuthorizationHeader,
  getUserCredentials,
} from "../../utils/helpers";
import urls from "../../utils/urls.json";
import axios from "axios";
import {
  checkIfSubscribedTo,
  DOMAIN,
  FIREBASE_CONFIG,
  PLAN,
} from "../../utils/config";
import RestaurantLayout from "../../pages/dashboard/restaurant/layout";
import Loading from "../loading";
import { setShowFreeTrialBanner } from "../../redux/utils";

import { ref, onChildRemoved, onChildAdded, onValue } from "firebase/database";
import { database } from "../../utils/firebase";

export default function Protected({ restaurantLayout }) {
  const [loaded, setLoaded] = useState(false);

  const authSlice = useSelector((state) => state.auth);
  const isAuthenticated = authSlice.isAuthenticated;

  const { fetchedRestaurants } = useSelector((state) => state.restaurant);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { restaurantId } = useParams();

  const fetchNewSession = useCallback(
    (sessionId, restaurantId) => {
      axios({
        method: "GET",
        url: `${DOMAIN}/orders/sessions/`,
        headers: getAuthorizationHeader(),
        params: {
          organization_uid: restaurantId,
          id: sessionId,
        },
      })
        .then((res) => {
          if (res.data.length) dispatch(addNewSessionAndCustomer(res.data[0]));
        })
        .catch((err) => console.error(err));
    },
    [dispatch]
  );

  const handleActiveOrder = useCallback(
    (orderIds) => {
      dispatch(setActiveOrders(orderIds));
      axios({
        method: "POST",
        url: `${DOMAIN}/orders/pending/`,
        headers: getAuthorizationHeader(),
        params: {
          organization_uid: restaurantId,
        },
        data: {
          ids: orderIds,
        },
      })
        .then((res) => {
          dispatch(setPendingOrders(res.data));
          dispatch(setFetchedActiveOrders(true));
        })
        .catch((err) => console.error(err));
    },
    [restaurantId, dispatch]
  );

  useEffect(() => {
    if (fetchedRestaurants && restaurantId) {
      // ACTIVE_ORDERS:ref
      const activeOrdersRef = ref(
        database,
        `${FIREBASE_CONFIG.COLLECTION_NAME}/` +
          restaurantId +
          `/${FIREBASE_CONFIG.ACTIVE_ORDERS}`
      );
      onValue(activeOrdersRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const orderIds = Object.values(data);
          handleActiveOrder(orderIds);
        } else {
          dispatch(setActiveOrders([]));
          dispatch(setPendingOrders([]));
          dispatch(setFetchedActiveOrders(true));
        }
      });
    }
  }, [dispatch, fetchedRestaurants, restaurantId, handleActiveOrder]);

  const setupFirebaseSocket = useCallback(
    (restaurantId) => {
      // BOOKING_CODES:ref
      const bookingCodesRef = ref(
        database,
        `${FIREBASE_CONFIG.COLLECTION_NAME}/` +
          restaurantId +
          `/${FIREBASE_CONFIG.BOOKING_CODES}`
      );
      // BOOKING_CODES:ADDED
      onChildAdded(bookingCodesRef, (snapshot) => {
        const data = snapshot.val();
        dispatch(addBookingCodeRequests(data));
      });
      // BOOKING_CODES:REMOVED
      onChildRemoved(bookingCodesRef, (snapshot) => {
        const data = snapshot.val();
        fetchNewSession(data.session_id, restaurantId);
        dispatch(removeBookingCodeRequests(data));
      });

      // BILL_REQUESTS:ref
      const billRequestRef = ref(
        database,
        `${FIREBASE_CONFIG.COLLECTION_NAME}/` +
          restaurantId +
          `/${FIREBASE_CONFIG.BILL_REQUESTS}`
      );
      // BILL_REQUESTS:ADDED
      onChildAdded(billRequestRef, (snapshot) => {
        const data = snapshot.val();
        dispatch(addRequestingBill(data));
      });
      // BILL_REQUESTS:REMOVED
      onChildRemoved(billRequestRef, (snapshot) => {
        const data = snapshot.val();
        dispatch(removeRequestingBill(data));
      });

      // CALLING_TABLES:ref
      const callingRef = ref(
        database,
        `${FIREBASE_CONFIG.COLLECTION_NAME}/` +
          restaurantId +
          `/${FIREBASE_CONFIG.CALLING_TABLES}`
      );
      // CALLING_TABLES:ADDED
      onChildAdded(callingRef, (snapshot) => {
        const data = snapshot.val();
        dispatch(addCallingTables(data));
      });
      // CALLING_TABLES:REMOVED
      onChildRemoved(callingRef, (snapshot) => {
        const data = snapshot.val();
        dispatch(removeCallingTables(data));
      });
    },
    [dispatch, fetchNewSession]
  );

  const setupRestaurant = useCallback(() => {
    if (!fetchedRestaurants) {
      Promise.all([
        axios({
          method: "GET",
          url: `${DOMAIN}/organizations/access-categories/`,
          headers: getAuthorizationHeader(),
        }),
        axios({
          method: "GET",
          url: `${DOMAIN}/menus/tags/`,
          headers: getAuthorizationHeader(),
        }),
        axios({
          method: "GET",
          url: `${DOMAIN}/tables/tags/`,
          headers: getAuthorizationHeader(),
        }),
        axios({
          method: "GET",
          url: `${DOMAIN}/organizations/`,
          headers: getAuthorizationHeader(),
        }),
      ])
        .then(([accessCategoryRes, tagsRes, tableTagsRes, restaurantsRes]) => {
          dispatch(setAccessCategory(accessCategoryRes.data));
          dispatch(setTags(tagsRes.data));
          dispatch(setTableTags(tableTagsRes.data));

          dispatch(setRestaurants(restaurantsRes.data));
          if (restaurantsRes.data.length) {
            dispatch(setRestaurant(restaurantsRes.data[0]));
            dispatch(
              setShowFreeTrialBanner(
                (restaurantsRes.data[0].subscription.razor_status ===
                  "created" ||
                  restaurantsRes.data[0].subscription.razor_status ===
                    "APPROVAL_PENDING") &&
                  Boolean(restaurantsRes.data[0].trial_ending_on)
              )
            );
            Promise.all([
              axios({
                method: "GET",
                url: `${DOMAIN}/organizations/members/`,
                headers: getAuthorizationHeader(),
                params: {
                  organization_uid: restaurantsRes.data[0].uid,
                },
              }),
              axios({
                method: "GET",
                url: `${DOMAIN}/menus/`,
                headers: getAuthorizationHeader(),
                params: {
                  organization_uid: restaurantsRes.data[0].uid,
                },
              }),
              checkIfSubscribedTo(restaurantsRes.data[0].plan_code, PLAN.PRO)
                ? axios({
                    method: "GET",
                    url: `${DOMAIN}/tables/`,
                    headers: getAuthorizationHeader(),
                    params: {
                      organization_uid: restaurantsRes.data[0].uid,
                    },
                  })
                : Promise.resolve(),
              checkIfSubscribedTo(restaurantsRes.data[0].plan_code, PLAN.PRO)
                ? axios({
                    method: "GET",
                    url: `${DOMAIN}/orders/sessions/`,
                    headers: getAuthorizationHeader(),
                    params: {
                      organization_uid: restaurantsRes.data[0].uid,
                    },
                  })
                : Promise.resolve(),
              checkIfSubscribedTo(restaurantsRes.data[0].plan_code, PLAN.PRO)
                ? axios({
                    method: "GET",
                    url: `${DOMAIN}/orders/`,
                    headers: getAuthorizationHeader(),
                    params: {
                      organization_uid: restaurantsRes.data[0].uid,
                    },
                  })
                : Promise.resolve(),
            ])
              .then(
                ([membersRes, menusRes, tablesRes, sessionsRes, ordersRes]) => {
                  const tempSessions = [];
                  const tempCustomers = [];

                  if (sessionsRes)
                    sessionsRes.data.forEach((session) => {
                      const { customer_details, ...rest } = session;
                      tempSessions.push(rest);
                      tempCustomers.push(customer_details);
                    });

                  if (ordersRes) dispatch(setOrders(ordersRes.data));

                  dispatch(setCustomers(tempCustomers));
                  dispatch(setSessions(tempSessions));

                  if (membersRes.data.length) {
                    dispatch(setMembers(membersRes.data));
                  }

                  const { menu, itemCategories, items, variants } =
                    breakMenusResponse(menusRes.data);

                  if (tablesRes) {
                    const table = tablesRes.data.filter((i) => !i.is_in_room);
                    const room = tablesRes.data.filter((i) => i.is_in_room);
                    dispatch(setTables(table));
                    dispatch(setRooms(room));
                  }

                  dispatch(setMenu(menu));
                  dispatch(setItemCategories(itemCategories));
                  dispatch(setItems(items));
                  dispatch(setVariants(variants));
                  setupFirebaseSocket(restaurantsRes.data[0].uid);
                }
              )
              .catch((err) => {
                console.error(err);
              })
              .finally(() => {
                dispatch(setFetchedRestaurants(true));
                if (window.location.pathname === "/") {
                  navigate(`/${restaurantsRes.data[0].uid}`);
                }
              });
          } else {
            dispatch(setFetchedRestaurants(true));
            navigate(urls.dashboard.path);
          }
        })
        .catch((err) => {
          console.error(err.request.status);
          if (err.request.status === 401) {
            deleteUserCredentials();
            dispatch(deAuthenticate());
            navigate(urls.auth.children.login.absolutePath);
          }
        });
    }
  }, [fetchedRestaurants, dispatch, navigate, setupFirebaseSocket]);

  useEffect(() => {
    const credentials = getUserCredentials();
    if (
      credentials.accessToken &&
      credentials.refreshToken &&
      credentials.user
    ) {
      dispatch(authenticate({ user: credentials.user }));
      setupRestaurant();
    } else {
      dispatch(deAuthenticate());
      navigate(urls.auth.children.login.absolutePath);
    }
    setLoaded(true);
  }, [dispatch, navigate, setupRestaurant]);

  return (
    <Loading
      loading={!loaded || !isAuthenticated || !fetchedRestaurants}
      minHeight={"100vh"}
    >
      {restaurantLayout ? (
        <RestaurantLayout>
          <Outlet />
        </RestaurantLayout>
      ) : (
        <Outlet />
      )}
    </Loading>
  );
}

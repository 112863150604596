import { Stack, Typography } from "@mui/joy";
import { Link, useParams } from "react-router-dom";

export default function Body() {
  const { restaurantId } = useParams();

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "200px",
      }}
    >
      <Stack spacing={1}>
        <Typography level="title-md" textAlign="center">
          Your plan does not includes <b>Restaurant Summary</b>
        </Typography>

        <Typography level="body-xs" textAlign="center">
          Please consider upgrading or continue to{" "}
          <Link to={`/${restaurantId}/menu`}>
            <Typography
              level="body-xs"
              sx={{
                textDecoration: "underline",
              }}
            >
              Menu Page
            </Typography>
          </Link>
        </Typography>
      </Stack>
    </Stack>
  );
}

import { Stack, Input, Tooltip, Select, Option, Typography } from "@mui/joy";
import { Search, FilterList } from "@mui/icons-material";

export default function FilterRow() {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" alignItems="center" spacing={1.5}>
        <Select value={1} size="sm" startDecorator={<FilterList />}>
          <Option value={1}>Today</Option>
          <Tooltip title="Coming soon" size="sm">
            <Option value={2}>
              <Typography level="body-sm" textColor="neutral.400">
                Yesterday
              </Typography>
            </Option>
          </Tooltip>
          <Tooltip title="Coming soon" size="sm">
            <Option value={3}>
              <Typography level="body-sm" textColor="neutral.400">
                Last 7 days
              </Typography>
            </Option>
          </Tooltip>
        </Select>
        <Tooltip size="sm" title="Coming Soon.." variant="solid">
          <Input
            startDecorator={<Search />}
            placeholder="Search in Tables"
            size="sm"
            sx={{
              minWidth: "300px",
            }}
            value=""
          />
        </Tooltip>
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={1.5}
      ></Stack>
    </Stack>
  );
}

import { KeyboardArrowDown } from "@mui/icons-material";
import { Button, Stack } from "@mui/joy";

export default function Filters() {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Button
        size="sm"
        variant="outlined"
        color="neutral"
        endDecorator={<KeyboardArrowDown fontSize="small" />}
      >
        This Month
      </Button>
    </Stack>
  );
}

import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { setActivePathId } from "../../../../../redux/utils";

import { Container, Stack } from "@mui/joy";

import urls from "../../../../../utils/urls.json";
import BodyWrapper from "../../components/bodyWrapper";
import SettingHeader from "../components/header";
import UpdateRestaurantDetails from "./updateRestaurantDetails";

export default function RestaurantDetails() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActivePathId(urls.restaurant.children.settings.pathId));
  }, [dispatch]);

  return (
    <BodyWrapper withStack>
      <Stack justifyContent="center" alignItems="center">
        <Container maxWidth="lg">
          <SettingHeader
            title={
              urls.restaurant.children.settings.children.restaurantDetails.name
            }
          />
          <UpdateRestaurantDetails />
        </Container>
      </Stack>
    </BodyWrapper>
  );
}

import { Stack, Typography, Grid } from "@mui/joy";

import OccupiedTable from "../../components/table/occupied";

export default function Occupied({ isRoom, activeSessions }) {
  return (
    <Stack spacing={1}>
      <Typography level="title-lg">
        <b>Occupied</b> ({activeSessions.length})
      </Typography>
      <Grid container rowSpacing={2} columnSpacing={3}>
        {activeSessions.length ? (
          activeSessions.map((session, index) => (
            <OccupiedTable session={session} key={index} isRoom={isRoom} />
          ))
        ) : (
          <Stack
            direction="column"
            minHeight="50px"
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              level="body-sm"
              textColor="neutral.400"
              textAlign="center"
            >
              No Occupied {isRoom ? "Room" : "Table"}s
            </Typography>
          </Stack>
        )}
      </Grid>
    </Stack>
  );
}

import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setActivePathId } from "../../../../redux/utils";

import { Container, Grid, Stack, Typography } from "@mui/joy";

import urls from "../../../../utils/urls.json";
import BodyWrapper from "../components/bodyWrapper";
import { Link } from "react-router-dom";
import { ArrowForwardIos } from "@mui/icons-material";

export default function Settings() {
  const { restaurant } = useSelector((state) => state.restaurant);

  const dispatch = useDispatch();

  const setting_groups = [
    {
      title: "Restaurant Settings",
      settings: [
        {
          title: "Details",
          description: "Manage settings such as your restaurant name and logo",
          link: `/${restaurant.uid}/${urls.restaurant.children.settings.path}/${urls.restaurant.children.settings.children.restaurantDetails.path}`,
        },
        {
          title: "Timing",
          description: "Set up bookable resources and assign them to services",
          link: `/${restaurant.uid}/${urls.restaurant.children.settings.path}/${urls.restaurant.children.settings.children.timings.path}`,
          disabled: true,
        },
        {
          title: "Closed dates",
          description: "Set the dates when your restaurant is closed",
          link: `/${restaurant.uid}/${urls.restaurant.children.settings.path}/${urls.restaurant.children.settings.children.dates.path}`,
          disabled: true,
        },
      ],
    },
    {
      title: "Staff",
      settings: [
        {
          title: "Staff Members",
          description: "Add, edit and delete your staff members",
          link: `/${restaurant.uid}/${urls.restaurant.children.settings.path}/${urls.restaurant.children.settings.children.team.path}`,
        },
        {
          title: "Permissions",
          description:
            "Configure level of access to Smart Dine-in for each of your staff member",
          link: `/${restaurant.uid}/${urls.restaurant.children.settings.path}/${urls.restaurant.children.settings.children.permissions.path}`,
        },
      ],
    },
  ];

  useEffect(() => {
    dispatch(setActivePathId(urls.restaurant.children.settings.pathId));
  }, [dispatch]);

  const Item = ({ item, disableUnderline }) => (
    <Stack
      direction="row"
      sx={{
        padding: "15px 20px 15px 20px",
        cursor: item.disabled ? "default" : "pointer",
        "&:hover": {
          backgroundColor: item.disabled ? "inherit" : "#f1f1f1",
        },
        borderBottom: disableUnderline ? "none" : "1px solid #eee",
      }}
      justifyContent="space-between"
      alignItems="center"
      component={item.disabled ? "div" : Link}
      to={item.link}
    >
      <Stack spacing={1}>
        <Typography
          level={item.disabled ? "body-sm" : "title-sm"}
          fontWeight="bold"
          textColor={item.disabled ? "neutral.400" : "neutral.500"}
        >
          {item.title} <small>{item.disabled ? "(coming soon)" : null}</small>
        </Typography>
        <Typography
          level="body-xs"
          textColor={item.disabled ? "neutral.400" : "neutral.500"}
        >
          {item.description}
        </Typography>
      </Stack>
      <ArrowForwardIos fontSize="small" color="disabled" />
    </Stack>
  );

  const Wrapper = ({ setting }) => (
    <Stack
      sx={{
        width: "100%",
        borderRadius: "8px",
        backgroundColor: "#fff",
      }}
      boxShadow="sm"
    >
      <Typography
        level="h4"
        fontWeight="bolder"
        sx={{
          padding: "15px 20px 10px 20px",
          backgroundColor: "#fff",
          borderRadius: "8px 8px 0 0",
        }}
      >
        {setting.title}
      </Typography>
      <Stack>
        {setting.settings.map((item, index) => (
          <Item
            key={index}
            disableUnderline={index === setting.settings.length - 1}
            item={item}
          />
        ))}
      </Stack>
    </Stack>
  );

  return (
    <BodyWrapper withStack>
      <Stack justifyContent="center" alignItems="center">
        <Container maxWidth="lg">
          <Stack spacing={2}>
            <Grid container>
              {setting_groups.map((setting, index) => (
                <Grid key={index} xs={12} md={6} padding={2}>
                  <Wrapper setting={setting} />
                </Grid>
              ))}
            </Grid>
          </Stack>
        </Container>
      </Stack>
    </BodyWrapper>
  );
}

import { useState } from "react";
import { useSelector } from "react-redux";

import {
  Stack,
  Button,
  Modal,
  Typography,
  Sheet,
  Divider,
  Tooltip,
  AccordionGroup,
} from "@mui/joy";

import { OpenInFull, Person, ShoppingCartOutlined } from "@mui/icons-material";
import moment from "moment";
import { getTagIcon } from "../../../../../../../utils/helpers";
import TableOrder from "./tableOrder";
import MarkedAsBilled from "../../../../tables/occupied/markAsBilled";
import { getCurrency, isMobile } from "../../../../../../../utils/config";

export default function TableDetails({
  table,
  session,
  customer,
  tableOrders,
  amount,
  tableTags,
}) {
  const { restaurant } = useSelector((state) => state.restaurant);

  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        fullWidth
        startDecorator={<OpenInFull />}
        variant="outlined"
        color="neutral"
        size="sm"
        onClick={() => setOpen(true)}
      >
        View Details
      </Button>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          px: 1,
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            minWidth: isMobile() ? "auto" : "400px",
            maxWidth: "450px",
            maxHeight: isMobile() ? "75vh" : "90vh",
            overflow: "scroll",
            borderRadius: "md",
            p: 3,
          }}
        >
          <Stack spacing={3}>
            {/* header */}
            <Stack spacing={1}>
              <Stack direction="row" justifyContent="space-between">
                <Typography level="title-lg">{table.name}</Typography>
              </Stack>
              <Typography level="body-sm" textColor="neutral.400">
                Following is the detailed information about <b>{table.name}</b>.
              </Typography>
            </Stack>
            {/* body */}
            <Stack spacing={2}>
              <Stack spacing={1.5}>
                <Stack direction="row" spacing={0.75} alignItems="center">
                  <Person fontSize="lg" />
                  <Typography level="body-md" fontWeight={700}>
                    Customer Details
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Stack>
                    <Typography
                      level="body-sm"
                      textColor="neutral.400"
                      fontWeight={700}
                    >
                      Name
                    </Typography>
                    <Typography fontWeight={700}>{customer.name}</Typography>
                  </Stack>
                  <Stack alignItems="flex-end">
                    <Typography
                      level="body-sm"
                      textColor="neutral.400"
                      fontWeight={700}
                    >
                      Phone
                    </Typography>
                    <Typography fontWeight={700}>{customer.phone}</Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Divider orientation="horizontal" />
              <Stack direction="row" spacing={0.75} alignItems="center">
                <ShoppingCartOutlined fontSize="lg" />
                <Typography level="body-md" fontWeight={700}>
                  Orders
                </Typography>
              </Stack>
              <AccordionGroup>
                {tableOrders
                  .sort((a, b) => a.id - b.id)
                  .map((order, index) => (
                    <TableOrder order={order} key={index} seqNo={index + 1} />
                  ))}
              </AccordionGroup>
              <Stack spacing={1.5}>
                <Stack direction="row" justifyContent="space-between">
                  <Stack>
                    <Typography
                      level="body-sm"
                      textColor="neutral.400"
                      fontWeight={700}
                    >
                      Total Amount
                    </Typography>
                    <Typography fontWeight={700}>
                      {getCurrency(restaurant.currency)}
                      {amount}
                    </Typography>
                  </Stack>
                  <Stack alignItems="flex-end">
                    <Typography
                      level="body-sm"
                      textColor="neutral.400"
                      fontWeight={700}
                    >
                      Booked at
                    </Typography>
                    <Typography fontWeight={700}>
                      {moment(session.booked_on).format("LT")}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Stack>
                    <Typography
                      level="body-sm"
                      textColor="neutral.400"
                      fontWeight={700}
                    >
                      Capacity
                    </Typography>
                    <Typography fontWeight={700}>{table.capacity}</Typography>
                  </Stack>
                  <Stack alignItems="flex-end">
                    <Typography
                      level="body-sm"
                      textColor="neutral.400"
                      fontWeight={700}
                    >
                      Tags
                    </Typography>
                    <Stack direction="row" spacing={0.75} alignItems="center">
                      {tableTags.length ? (
                        tableTags.map((t, index) => (
                          <Tooltip title={t.name} size="sm" key={index}>
                            {getTagIcon(t, { fontSize: "small", key: index })}
                          </Tooltip>
                        ))
                      ) : (
                        <Typography level="body-xs">-</Typography>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            {/* footer */}
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1.5}
            >
              <MarkedAsBilled table={table} sessionId={session.id} />
              <Button
                fullWidth
                color="neutral"
                variant="outlined"
                size="sm"
                onClick={() => setOpen(false)}
              >
                Close
              </Button>
            </Stack>
          </Stack>
        </Sheet>
      </Modal>
    </>
  );
}

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import {
  AccessTime,
  SellOutlined,
  AttachMoney,
  Person,
  PeopleAltOutlined,
  ShoppingCartOutlined,
} from "@mui/icons-material";
import { Stack, Typography, Grid, Tooltip } from "@mui/joy";

import moment from "moment";

import { getTagIcon } from "../../../../../../utils/helpers";

import CallingRequest from "./callingRequest";
import BillRequest from "./billRequest";
import MarkedAsBilled from "../../../tables/occupied/markAsBilled";
import TableDetails from "./tableDetails";
import MoreSettings from "./moreSettings";
import { getCurrency } from "../../../../../../utils/config";

export default function OccupiedTable({ session, isRoom }) {
  const {
    restaurant,
    tableTags,
    tables,
    callingTables,
    requestingBill,
    pendingOrders,
    orders,
    customers,
  } = useSelector((state) => state.restaurant);

  const [loaded, setLoaded] = useState(false);
  const [table, setTable] = useState({});
  const [customer, setCustomer] = useState({});
  const [tableOrders, setTableOrders] = useState({});
  const [amount, setAmount] = useState(0);

  const [isCalling, setIsCalling] = useState(false);
  const [isRequestingBill, setIsRequestingBill] = useState(false);

  useEffect(() => {
    setTable(tables.filter((i) => i.id === session.table)[0]);
    setCustomer(customers.filter((i) => i.id === session.customer)[0]);

    const tableOrders = [...orders, ...pendingOrders].filter(
      (i) => i.session === session.id
    );
    setTableOrders(tableOrders);

    setAmount(
      tableOrders
        .map((i) => i.total_amount)
        .reduce((partialSum, a) => partialSum + a, 0)
    );

    setLoaded(true);
  }, [
    pendingOrders,
    orders,
    session.table,
    loaded,
    tables,
    customers,
    session.customer,
    session.id,
  ]);

  useEffect(() => {
    if (loaded) {
      const isInList = callingTables.filter((i) => i === table.id);
      if (isInList.length) setIsCalling(true);
      else setIsCalling(false);
    }
  }, [session.table, loaded, callingTables, table.id]);

  useEffect(() => {
    if (loaded) {
      const isInList = requestingBill.filter((i) => i === table.id);
      if (isInList.length) setIsRequestingBill(true);
      else setIsRequestingBill(false);
    }
  }, [session.table, loaded, requestingBill, table.id]);

  const getTableTags = (table) => {
    let selectedTags = [];
    table.tags.forEach((tId) => {
      selectedTags = [
        ...selectedTags,
        ...tableTags.filter((i) => i.id === tId),
      ];
    });
    return selectedTags;
  };

  return loaded ? (
    <Grid xs={12} sm={6} md={4} xl={3}>
      <Stack
        border="1px solid #F7B200"
        boxShadow="0px 0px 10px 0px rgba(247, 178, 0, 0.25)"
        bgcolor="#fff"
        borderRadius="6px"
        px={2}
        py={1}
        spacing={1}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            minHeight: "35px",
          }}
        >
          <Typography level="title-md" fontWeight={700}>
            {table.name}
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            {isCalling && (
              <CallingRequest table={table} sessionId={session.id} />
            )}
            {isRequestingBill && (
              <BillRequest table={table} sessionId={session.id} />
            )}
            <MoreSettings session={session} table={table} />
          </Stack>
        </Stack>
        <Grid container rowGap={1}>
          <Grid xs={6}>
            <Stack spacing={0.25}>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <ShoppingCartOutlined fontSize="small" />
                <Typography level="body-sm" textColor="neutral.400">
                  Orders:
                </Typography>
                <Typography level="title-sm" fontWeight={700}>
                  {tableOrders.length}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <AttachMoney fontSize="small" />
                <Typography level="body-sm" textColor="neutral.400">
                  Amount:
                </Typography>
                <Typography level="title-sm" fontWeight={700}>
                  {getCurrency(restaurant.currency)} {amount ? amount : 0}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <PeopleAltOutlined fontSize="small" />
                  <Typography level="body-sm" textColor="neutral.400">
                    Capacity:
                  </Typography>
                  <Typography level="title-sm" fontWeight={700}>
                    {table.capacity}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid xs={6}>
            <Stack spacing={0.25}>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <Person fontSize="small" />
                <Typography level="title-sm">
                  {customer.name || customer.phone}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={0.5}
                alignItems="center"
                sx={{
                  display: isRoom ? "none" : "flex",
                }}
              >
                <SellOutlined fontSize="small" />
                <Typography level="body-sm" textColor="neutral.400">
                  Tags:
                </Typography>
                <Stack direction="row" spacing={0.75} alignItems="center">
                  {getTableTags(table).length ? (
                    getTableTags(table).map((t, index) => (
                      <Tooltip title={t.name} size="sm" key={index}>
                        {getTagIcon(t, { fontSize: "small", key: index })}
                      </Tooltip>
                    ))
                  ) : (
                    <Typography level="body-xs">-</Typography>
                  )}
                </Stack>
              </Stack>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <AccessTime fontSize="small" />
                <Typography level="title-sm">
                  {moment(session.booked_on).format("LT")}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Stack direction="row" spacing={2}>
          <MarkedAsBilled table={table} sessionId={session.id} />
          <TableDetails
            table={table}
            customer={customer}
            tableOrders={tableOrders}
            amount={amount}
            session={session}
            tableTags={getTableTags(table)}
          />
        </Stack>
      </Stack>
    </Grid>
  ) : null;
}

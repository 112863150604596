import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { setActivePathId } from "../../../../../redux/utils";

import { Container, Stack } from "@mui/joy";

import urls from "../../../../../utils/urls.json";
import BodyWrapper from "../../components/bodyWrapper";
import SettingHeader from "../components/header";

export default function Timings() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActivePathId(urls.restaurant.children.settings.pathId));
  }, [dispatch]);
  return (
    <BodyWrapper withStack>
      <Stack justifyContent="center" alignItems="center">
        <Container
          maxWidth="lg"
          sx={{
            padding: "0 !important",
          }}
        >
          <SettingHeader
            title={
              urls.restaurant.children.settings.children.timings.name
            }
          />
        </Container>
      </Stack>
    </BodyWrapper>
  );
}

import { useSelector } from "react-redux";

import { Box, Checkbox, Sheet, Stack, Table, Typography } from "@mui/joy";

export default function PermissionsList() {
  const { accessCategory } = useSelector((state) => state.restaurant);

  return (
    <Box mt={"0 !important"} boxShadow="sm" borderRadius="6px">
      <Sheet
        sx={{
          bgcolor: "#ffffff",
          borderRadius: "6px",
          padding: "10px 20px",
        }}
      >
        <Table>
          <thead>
            <tr>
              <th colSpan={3}>Permission</th>
              <th colSpan={1}>Low</th>
              <th colSpan={1}>Medium</th>
              <th colSpan={1}>High</th>
              <th colSpan={1}>Admin</th>
            </tr>
          </thead>
          {accessCategory.map((accessCategory, index) => (
            <tbody key={index}>
              <tr>
                <td
                  colSpan={7}
                  style={{
                    borderBottom: "none",
                  }}
                >
                  <Typography level="title-lg">
                    {accessCategory.name}
                  </Typography>
                </td>
              </tr>
              {accessCategory.accesses.map((access, i) => (
                <tr key={i}>
                  <td colSpan={3}>
                    <Stack spacing={1}>
                      <Typography level="title-sm">{access.title}</Typography>
                      {access.description ? (
                        <Typography level="title-sm">
                          {access.description}
                        </Typography>
                      ) : null}
                    </Stack>
                  </td>
                  <td>
                    <Checkbox
                      checked={access.default_to_low_group}
                      disabled
                      size="sm"
                    />
                  </td>
                  <td>
                    <Checkbox
                      checked={access.default_to_medium_group}
                      disabled
                      size="sm"
                    />
                  </td>
                  <td>
                    <Checkbox
                      checked={access.default_to_high_group}
                      disabled
                      size="sm"
                    />
                  </td>
                  <td>
                    <Checkbox
                      checked={access.default_to_admin_group}
                      disabled
                      size="sm"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          ))}
        </Table>
      </Sheet>
    </Box>
  );
}

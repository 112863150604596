import { createSlice } from "@reduxjs/toolkit";

const defaultNotify = {
  open: false,
  action: null,
  severity: null,
  autoHideDuration: 3000,
  vertical: "bottom",
  horizontal: "right",
};

export const utilsSlice = createSlice({
  name: "utils",
  initialState: {
    activePathId: null,
    showFreeTrialBanner: false,
    notify: defaultNotify,
    sidebarOpen: false,
  },
  reducers: {
    setActivePathId: (state, { payload }) => {
      localStorage.setItem("activePathId", payload);
      state.activePathId = payload;
    },
    setShowFreeTrialBanner: (state, { payload }) => {
      state.showFreeTrialBanner = payload;
    },
    setNotify: (state, { payload }) => {
      state.notify = {
        ...defaultNotify,
        ...payload,
      };
    },
    setSidebarOpen: (state, { payload }) => {
      state.sidebarOpen = payload;
    },
    resetNotify: (state) => {
      state.notify = defaultNotify;
    },
  },
});

export const {
  setActivePathId,
  setShowFreeTrialBanner,
  setNotify,
  setSidebarOpen,
  resetNotify,
} = utilsSlice.actions;

export default utilsSlice.reducer;

import { useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { setMembers } from "../../../../../redux/restaurant";

import { Switch, Typography } from "@mui/joy";

import { DOMAIN } from "../../../../../utils/config";
import { getAuthorizationHeader } from "../../../../../utils/helpers";
import axios from "axios";
import { setNotify } from "../../../../../redux/utils";
import EditMember from "./editMember";

export default function Member({ member }) {
  const { members, restaurant } = useSelector((state) => state.restaurant);

  const [savingToggleLoginBlocked, setSavingToggleLoginBlocked] =
    useState(false);

  const dispatch = useDispatch();

  const getGroup = (groupId) => {
    const group = restaurant.access_groups.filter((i) => i.id === groupId)[0];
    return group.name;
  };

  const toggleLoginBlocked = () => {
    setSavingToggleLoginBlocked(true);
    axios({
      method: "PATCH",
      url: `${DOMAIN}/organizations/members/`,
      headers: getAuthorizationHeader(),
      params: {
        organization_uid: restaurant.uid,
        id: member.id,
      },
      data: {
        is_login_blocked: !member.is_login_blocked,
      },
    })
      .then((res) => {
        setSavingToggleLoginBlocked(false);
        dispatch(
          setMembers(
            members.map((i) => {
              if (i.id === member.id) return res.data;
              return i;
            })
          )
        );
      })
      .catch((err) => {
        let errorMessage = "Unable to perform this action!";
        try {
          errorMessage = JSON.parse(err.request.response).message;
        } catch (error) {}
        setSavingToggleLoginBlocked(false);
        dispatch(
          setNotify({
            open: true,
            action: errorMessage,
            severity: "danger",
            autoHideDuration: 5000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      });
  };

  return (
    <tr>
      <td colSpan={3}>
        <Typography level="title-sm" fontWeight={700}>
          {member.user_details.first_name} {member.user_details.last_name}
        </Typography>
      </td>
      <td colSpan={2}>{getGroup(member.group)}</td>
      <td colSpan={4}>{member.user_details.email}</td>
      <td colSpan={3}>
        {member.additional_accesses.length
          ? `+${member.additional_accesses.length}`
          : "0"}
      </td>
      <td colSpan={2}>
        <Switch
          disabled={savingToggleLoginBlocked}
          checked={member.is_login_blocked}
          color={member.is_login_blocked ? "danger" : "neutral"}
          variant={member.is_login_blocked ? "solid" : "outlined"}
          onChange={toggleLoginBlocked}
        />
      </td>
      <td>
        <EditMember member={member} />
      </td>
    </tr>
  );
}

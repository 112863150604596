import { Card, Container, Sheet, Stack, Typography } from "@mui/joy";

export default function AuthWrapper({ children }) {
  return (
    <Stack
      justifyContent="space-between"
      sx={{
        minHeight: "100vh",
        bgcolor: "#fafafa",
      }}
    >
      <Stack>
        <Sheet
          sx={{
            bgcolor: "#fff",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            py={2}
            px={2}
            boxShadow="sm"
          >
            <Stack direction="row" spacing={0.5}>
              <Typography level="h3" component="h1" fontWeight={500}>
                Smart
              </Typography>
              <Typography level="h3" component="h1" fontWeight={700}>
                Dine-in
              </Typography>
            </Stack>
            <Stack direction="row" spacing={3} alignItems="center"></Stack>
          </Stack>
        </Sheet>
      </Stack>
      <Container
        maxWidth="xs"
        sx={{
          pb: 5,
        }}
      >
        <Card
          size="lg"
          sx={{
            backgroundColor: "#fff",
          }}
        >
          {children}
        </Card>
      </Container>
      <Stack></Stack>
    </Stack>
  );
}

import { useDispatch, useSelector } from "react-redux";

import { Download, Menu, Person, VpnKey } from "@mui/icons-material";
import { Stack, Typography, Grid, Tooltip, IconButton, Switch } from "@mui/joy";

import { getAuthorizationHeader, getTagIcon } from "../../../../utils/helpers";
import EditTable from "./editTable";
import axios from "axios";
import { DOMAIN } from "../../../../utils/config";
import { setRooms, setTables } from "../../../../redux/restaurant";
import { useState } from "react";
import { setNotify } from "../../../../redux/utils";
import DownloadQR from "../components/downloadQR";

export default function Available({ table, isRoom }) {
  const typeName = isRoom ? "Room" : "Table";

  const { restaurant, tableTags, rooms, tables } = useSelector(
    (state) => state.restaurant
  );

  const [changingLiveStatus, setChangingLiveStatus] = useState(false);
  const [changingSkipBookingCodeStatus, setChangingSkipBookingCodeStatus] =
    useState(false);

  const dispatch = useDispatch();

  const getTableTags = (table) => {
    let selectedTags = [];
    table.tags.forEach((tId) => {
      selectedTags = [
        ...selectedTags,
        ...tableTags.filter((i) => i.id === tId),
      ];
    });
    return selectedTags;
  };

  const changeLiveStatus = () => {
    setChangingLiveStatus(true);
    axios({
      method: "PATCH",
      url: `${DOMAIN}/tables/`,
      headers: getAuthorizationHeader(),
      params: {
        organization_uid: restaurant.uid,
        id: table.id,
      },
      data: {
        is_live: !table.is_live,
      },
    })
      .then((res) => {
        const data = res.data;
        if (isRoom)
          dispatch(
            setRooms(
              rooms.map((i) => {
                if (i.id === table.id) {
                  return data;
                } else return i;
              })
            )
          );
        else
          dispatch(
            setTables(
              tables.map((i) => {
                if (i.id === table.id) {
                  return data;
                } else return i;
              })
            )
          );

        setChangingLiveStatus(false);

        dispatch(
          setNotify({
            open: true,
            action: `${typeName} Updated!`,
            severity: "success",
            autoHideDuration: 2000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      })
      .catch((err) => {
        setChangingLiveStatus(false);
        let errorMessage = "Something went Wrong!";
        try {
          errorMessage = JSON.parse(err.request.response).message;
        } catch (error) {}
        dispatch(
          setNotify({
            open: true,
            action: errorMessage,
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      });
  };

  const changeSkipBookingCodeStatus = () => {
    setChangingSkipBookingCodeStatus(true);
    axios({
      method: "PATCH",
      url: `${DOMAIN}/tables/`,
      headers: getAuthorizationHeader(),
      params: {
        organization_uid: restaurant.uid,
        id: table.id,
      },
      data: {
        skip_booking_code: !table.skip_booking_code,
      },
    })
      .then((res) => {
        const data = res.data;
        if (isRoom)
          dispatch(
            setRooms(
              rooms.map((i) => {
                if (i.id === table.id) {
                  return data;
                } else return i;
              })
            )
          );
        else
          dispatch(
            setTables(
              tables.map((i) => {
                if (i.id === table.id) {
                  return data;
                } else return i;
              })
            )
          );

        setChangingSkipBookingCodeStatus(false);

        dispatch(
          setNotify({
            open: true,
            action: `${typeName} Updated!`,
            severity: "success",
            autoHideDuration: 2000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      })
      .catch((err) => {
        setChangingSkipBookingCodeStatus(false);
        let errorMessage = "Something went Wrong!";
        try {
          errorMessage = JSON.parse(err.request.response).message;
        } catch (error) {}
        dispatch(
          setNotify({
            open: true,
            action: errorMessage,
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      });
  };

  return (
    <Grid xs={12} sm={6} md={4} xl={3}>
      <Stack
        border="1px solid #E2E2E2"
        boxShadow="0px 0px 10px 0px rgbs(238, 238, 238, 0.25)"
        bgcolor="#fff"
        borderRadius="6px"
        px={2}
        py={1}
        spacing={1.5}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Tooltip title="Coming soon" size="sm">
              <Menu
                fontSize="small"
                sx={{
                  color: "#999",
                  cursor: "grab",
                }}
              />
            </Tooltip>
            <Typography level="title-md" fontWeight={700}>
              {table.name}
            </Typography>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={1.5}>
            <Tooltip
              title={table.is_live ? "Bookable" : "Not Bookable"}
              size="sm"
            >
              <Switch
                size="sm"
                color="neutral"
                variant={table.is_live ? "solid" : "outlined"}
                checked={table.is_live}
                disabled={changingLiveStatus}
                onChange={changeLiveStatus}
              />
            </Tooltip>
            <DownloadQR table={table}>
              <Tooltip title="Download Table QR" size="sm">
                <IconButton size="sm">
                  <Download />
                </IconButton>
              </Tooltip>
            </DownloadQR>
          </Stack>
        </Stack>
        <Grid container rowGap={1}>
          <Grid xs={6}>
            <Stack spacing={0.25}>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <Person fontSize="small" />
                <Typography level="body-sm">Capacity:</Typography>
                <Typography level="title-sm" fontWeight={700}>
                  {table.capacity}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          {!isRoom && (
            <Grid xs={6}>
              <Stack spacing={0.25}>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Typography level="body-sm">Tags:</Typography>
                  <Stack direction="row" spacing={0.75} alignItems="center">
                    {getTableTags(table).length ? (
                      getTableTags(table).map((t, index) => (
                        <Tooltip key={index} title={t.name} size="sm">
                          {getTagIcon(t, { fontSize: "small", key: index })}
                        </Tooltip>
                      ))
                    ) : (
                      <Typography level="body-xs">-</Typography>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          )}
          <Grid xs={12}>
            <Stack spacing={0.25}>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <VpnKey fontSize="small" />
                <Typography level="body-sm">Booking with Code</Typography>
                <Tooltip
                  title={!table.skip_booking_code ? "Enabled" : "Disabled"}
                  size="sm"
                >
                  <Switch
                    size="sm"
                    color={!table.skip_booking_code ? "neutral" : "danger"}
                    variant={!table.skip_booking_code ? "solid" : "outlined"}
                    checked={!table.skip_booking_code}
                    disabled={changingSkipBookingCodeStatus}
                    onChange={changeSkipBookingCodeStatus}
                  />
                </Tooltip>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Stack direction="row" spacing={2}>
          <EditTable isRoom={isRoom} table={table} />
        </Stack>
      </Stack>
    </Grid>
  );
}

import { useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { Stack, Grid, Typography, IconButton, Sheet, Switch } from "@mui/joy";

import { ChevronLeft, Circle, KeyboardArrowDown } from "@mui/icons-material";
import { setItems, setMenuEditor } from "../../../../../redux/restaurant";
import {
  getAuthorizationHeader,
  getTagIcon,
} from "../../../../../utils/helpers";
import axios from "axios";
import { DOMAIN } from "../../../../../utils/config";
import { setNotify } from "../../../../../redux/utils";

export default function ItemsSection() {
  const { restaurant, itemCategories, items, variants, tags, menuEditor } =
    useSelector((state) => state.restaurant);

  const [collapsed, setCollapsed] = useState(false);

  const dispatch = useDispatch();

  const getVariantCount = (item) => {
    return variants.filter((i) => i.item === item.id).length;
  };

  const getLiveVariantCount = (item) => {
    return variants.filter((i) => i.item === item.id && i.is_live).length;
  };

  const getCategoryName = (item) => {
    const categories = itemCategories.filter((i) => i.id === item.category);

    if (categories.length) {
      return categories[0].name;
    }
    return "";
  };

  const getItemTags = (item) => {
    let selectedTags = [];
    item.tags.forEach((tId) => {
      selectedTags = [...selectedTags, ...tags.filter((i) => i.id === tId)];
    });
    return selectedTags;
  };

  const setCurrentlyEditingItem = (item) => {
    dispatch(
      setMenuEditor({
        currentlyEditing: "item",
        entryId: item.id,
        saving: false,
      })
    );
  };

  const updateItemStockStatus = (item) => {
    axios({
      method: "PATCH",
      url: `${DOMAIN}/menus/items/`,
      headers: getAuthorizationHeader(),
      params: {
        uid: restaurant.uid,
        id: item.id,
      },
      data: {
        is_live: !item.is_live,
      },
    })
      .then((res) => {
        dispatch(
          setItems(
            items.map((i) => {
              if (i.id === item.id) {
                return {
                  ...i,
                  is_live: res.data.is_live,
                };
              }
              return i;
            })
          )
        );
        dispatch(
          setNotify({
            open: true,
            action: "Status Changed!",
            severity: "success",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      })
      .catch((err) => {
        let errorMessage = "Something went Wrong!";
        try {
          errorMessage = JSON.parse(err.request.response).message;
        } catch (error) {}
        dispatch(
          setNotify({
            open: true,
            action: errorMessage,
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      });
  };

  const getOrderedItems = () => {
    let itemList = items.map((i) => i);
    return itemList.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  };

  const showVariantCount = (item) => {
    const liveCount = getLiveVariantCount(item);
    const count = getVariantCount(item);

    if (liveCount === count) {
      return count;
    } else {
      return `${liveCount}/${count}`;
    }
  };

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography level="title-lg">
          <b>Items</b> {items.length ? `(${items.length})` : null}
        </Typography>
        <IconButton
          size="sm"
          color="neutral"
          variant="outlined"
          onClick={() => setCollapsed(!collapsed)}
        >
          {collapsed ? <ChevronLeft /> : <KeyboardArrowDown />}
        </IconButton>
      </Stack>
      <Grid container display={collapsed ? "none" : "flex"}>
        {items.length ? (
          getOrderedItems().map((item, index) => (
            <Grid
              xs={6}
              key={index}
              sx={{
                paddingLeft: index % 2 === 0 ? "none" : "10px",
                paddingRight: index % 2 === 0 ? "10px" : "none",
                paddingBottom: "15px",
              }}
            >
              <Sheet
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "6px",
                }}
              >
                <Stack
                  spacing={1}
                  borderRadius="6px 6px 0px 0px"
                  px={1.5}
                  py={1}
                  height="100%"
                  border={`1px solid ${
                    menuEditor.currentlyEditing === "item" &&
                    menuEditor.entryId === item.id
                      ? "#333333"
                      : "#EEEEEE"
                  }`}
                  boxShadow={
                    menuEditor.currentlyEditing === "item" &&
                    menuEditor.entryId === item.id
                      ? "lg"
                      : "none"
                  }
                  borderBottom="none"
                  onClick={() => setCurrentlyEditingItem(item)}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <Stack direction="row" spacing={0.75} alignItems="center">
                    <Circle
                      fontSize="small"
                      sx={{ color: item.is_veg ? "#00B620" : "#F90202" }}
                    />
                    <Typography level="title-md" fontWeight={700}>
                      {item.name}
                    </Typography>
                  </Stack>
                  <Typography level="body-xs" textColor="neutral.400">
                    {item.description.length > 65 ? (
                      <>
                        {item.description.slice(0, 65)} <b>read more..</b>
                      </>
                    ) : (
                      item.description
                    )}
                  </Typography>
                </Stack>
                <Stack
                  spacing={1.5}
                  borderRadius="0px 0px 6px 6px"
                  px={1}
                  py={1.5}
                  bgcolor="#F6F6F6"
                  height="100%"
                  border={`1px solid ${
                    menuEditor.currentlyEditing === "item" &&
                    menuEditor.entryId === item.id
                      ? "#333333"
                      : "#EEEEEE"
                  }`}
                  boxShadow={
                    menuEditor.currentlyEditing === "item" &&
                    menuEditor.entryId === item.id
                      ? "lg"
                      : "none"
                  }
                  borderTop="none"
                >
                  <Grid container rowGap={1.25}>
                    <Grid xs={7}>
                      <Stack direction="row" spacing={1} pl={1}>
                        <Typography level="body-sm">In Stock:</Typography>
                        <Switch
                          color="neutral"
                          size="sm"
                          checked={item.is_live}
                          variant={item.is_live ? "solid" : "outlined"}
                          onChange={() => updateItemStockStatus(item)}
                        />
                      </Stack>
                    </Grid>
                    <Grid xs={5}>
                      <Stack direction="row" spacing={1}>
                        <Typography level="body-sm">Variants:</Typography>
                        <Typography level="title-sm" fontWeight={700}>
                          {showVariantCount(item)}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid xs={7}>
                      <Stack direction="row" spacing={1} pl={1}>
                        <Typography
                          level="body-xs"
                          sx={{
                            bgcolor: "#333",
                            color: "#fff",
                            padding: "1px 8px",
                            borderRadius: "6px",
                          }}
                        >
                          {getCategoryName(item)}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid xs={5}>
                      <Stack direction="row" spacing={1}>
                        <Typography level="body-sm">Tags:</Typography>
                        <Stack
                          direction="row"
                          spacing={0.25}
                          alignItems="center"
                        >
                          {getItemTags(item).length ? (
                            getItemTags(item).map((t, index) =>
                              getTagIcon(t, { fontSize: "small", key: index })
                            )
                          ) : (
                            <Typography level="body-xs">-</Typography>
                          )}
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              </Sheet>
            </Grid>
          ))
        ) : (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            minHeight="100px"
            width="100%"
          >
            <Typography level="body-xs" textAlign="center">
              Add an Item (eg. Margherita Pizza)
            </Typography>
          </Stack>
        )}
      </Grid>
    </Stack>
  );
}

import { useState } from "react";

import { Stack, Button, Modal, Typography, Sheet, Divider } from "@mui/joy";

import { OpenInFull, Person, ShoppingCartOutlined } from "@mui/icons-material";
import OrderItem from "./orderItem";
import moment from "moment";
import ServeOrder from "../serveOrder";
import { getCurrency, isMobile } from "../../../../../../utils/config";
import { useSelector } from "react-redux";

export default function OrderDetails({ order, orderSeq, table, customer }) {
  const { restaurant } = useSelector((state) => state.restaurant);

  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        fullWidth
        startDecorator={<OpenInFull />}
        variant="outlined"
        color="neutral"
        size="sm"
        onClick={() => setOpen(true)}
      >
        View Details
      </Button>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          px: 1,
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            minWidth: isMobile() ? "auto" : "400px",
            maxWidth: "450px",
            maxHeight: isMobile() ? "75vh" : "90vh",
            overflow: "scroll",
            borderRadius: "md",
            p: 3,
          }}
        >
          <Stack spacing={3}>
            {/* header */}
            <Stack spacing={1}>
              <Stack direction="row" justifyContent="space-between">
                <Typography level="title-lg">{table.name}</Typography>
                <Typography level="title-lg">
                  Order <b>#{orderSeq}</b>
                </Typography>
              </Stack>
              <Typography level="body-sm" textColor="neutral.400">
                Following is the detailed information about the{" "}
                <b>Order #{orderSeq}</b> from <b>{table.name}</b>.
              </Typography>
            </Stack>
            {/* body */}
            <Stack spacing={2}>
              <Stack direction="row" spacing={0.75} alignItems="center">
                <ShoppingCartOutlined fontSize="lg" />
                <Typography level="body-md" fontWeight={700}>
                  Order Items
                </Typography>
              </Stack>
              <Stack spacing={1.5}>
                {order.order_items.map((order_item, index) => (
                  <OrderItem orderItem={order_item} key={index} />
                ))}
              </Stack>
              <Divider orientation="horizontal" />
              <Stack spacing={1.5}>
                <Stack direction="row" spacing={0.75} alignItems="center">
                  <Person fontSize="lg" />
                  <Typography level="body-md" fontWeight={700}>
                    Customer Details
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Stack>
                    <Typography
                      level="body-sm"
                      textColor="neutral.400"
                      fontWeight={700}
                    >
                      Name
                    </Typography>
                    <Typography fontWeight={700}>{customer.name}</Typography>
                  </Stack>
                  <Stack alignItems="flex-end">
                    <Typography
                      level="body-sm"
                      textColor="neutral.400"
                      fontWeight={700}
                    >
                      Phone
                    </Typography>
                    <Typography fontWeight={700}>{customer.phone}</Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Divider orientation="horizontal" />
              <Stack spacing={1.5}>
                <Stack direction="row" justifyContent="space-between">
                  <Stack>
                    <Typography
                      level="body-sm"
                      textColor="neutral.400"
                      fontWeight={700}
                    >
                      Amount
                    </Typography>
                    <Typography fontWeight={700}>
                      {getCurrency(restaurant.currency)}
                      {order.total_amount}
                    </Typography>
                  </Stack>
                  <Stack alignItems="flex-end">
                    <Typography
                      level="body-sm"
                      textColor="neutral.400"
                      fontWeight={700}
                    >
                      {order.completed_on
                        ? "Billed at"
                        : order.served_on
                        ? "Served at"
                        : "Placed at"}
                    </Typography>
                    <Typography fontWeight={700}>
                      {moment(
                        order.completed_on
                          ? order.completed_on
                          : order.served_on
                          ? order.served_on
                          : order.placed_on
                      ).format("LT")}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            {/* footer */}
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1.5}
            >
              <Button
                fullWidth
                color="neutral"
                variant="outlined"
                size="sm"
                onClick={() => setOpen(false)}
              >
                Close
              </Button>
              <ServeOrder order={order} orderSeq={orderSeq} table={table} />
            </Stack>
          </Stack>
        </Sheet>
      </Modal>
    </>
  );
}

import { Circle } from "@mui/icons-material";

import { Stack, Typography, Grid } from "@mui/joy";
import BookingRequest from "./bookingRequest";

export default function BookingRequestList({ isRoom, bookingRequests }) {
  return bookingRequests.length ? (
    <Stack spacing={1}>
      <Stack direction="row" spacing={0.75}>
        <Circle
          sx={{
            fontSize: "14px",
            color: "#F7B200",
          }}
        />
        <Typography level="title-lg">
          <b>New Booking Requests</b> ({bookingRequests.length})
        </Typography>
      </Stack>
      <Grid container rowSpacing={2} columnSpacing={3}>
        {bookingRequests.map((table, index) => (
          <BookingRequest table={table} isRoom={isRoom} key={index} />
        ))}
      </Grid>
    </Stack>
  ) : null;
}

import { Link, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { Button, IconButton, Stack, Typography, Badge } from "@mui/joy";
import { MenuOutlined, PersonOutline } from "@mui/icons-material";

import { setSidebarOpen } from "../../../../../redux/utils";

export default function Header() {
  const { restaurant, bookingCodeRequests } = useSelector(
    (state) => state.restaurant
  );
  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        padding: {
          xs: "15px 20px",
          lg: "15px 35px",
        },
      }}
    >
      <Stack direction="row" alignItems="center" spacing={1.5}>
        <Typography
          level="title-lg"
          fontWeight={700}
          sx={{
            cursor: "pointer",
          }}
          onClick={() => navigate(`/${restaurant.uid}`)}
        >
          {restaurant.name}
        </Typography>
      </Stack>
      <Button
        variant="plain"
        color="neutral"
        startDecorator={<PersonOutline />}
        component={Link}
        to="/account"
        sx={{
          display: {
            xs: "none",
            lg: "flex",
          },
        }}
      >
        {user.first_name} {user.last_name}
      </Button>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          display: {
            xs: "flex",
            lg: "none",
          },
        }}
      >
        <IconButton size="sm" component={Link} to="/account">
          <PersonOutline />
        </IconButton>
        <Stack direction="row">
          <IconButton size="sm" onClick={() => dispatch(setSidebarOpen(true))}>
            <Badge
              color="warning"
              size="sm"
              variant="solid"
              invisible={!Boolean(bookingCodeRequests.length)}
            >
              <MenuOutlined />
            </Badge>
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  );
}

import { Stack, Typography, Grid } from "@mui/joy";
import DismissBookingRequest from "./dismissBookingRequest";

export default function BookingRequest({ table }) {
  return (
    <Grid xs={12} sm={6} md={4} xl={3}>
      <Stack
        border="1px solid #E2E2E2"
        boxShadow="0px 0px 10px 0px rgbs(238, 238, 238, 0.25)"
        bgcolor="#fff"
        borderRadius="6px"
        px={2}
        py={1}
        spacing={1.5}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography level="title-md" fontWeight={700}>
              {table.name}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography level="body-md" textColor="neutral.400">
              Booking Code:
            </Typography>
            <Typography level="body-md" fontWeight={900}>
              {table.bookingCode}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography level="body-sm" fontWeight={900}>
              {table.bookingCustomerName || table.bookingCustomerPhone}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <DismissBookingRequest table={table} />
          </Stack>
        </Stack>
      </Stack>
    </Grid>
  );
}

import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Close } from "@mui/icons-material";
import {
  Stack,
  Button,
  Modal,
  ModalClose,
  Typography,
  Sheet,
  IconButton,
} from "@mui/joy";

import { DOMAIN, isMobile } from "../../../../utils/config";
import { getAuthorizationHeader } from "../../../../utils/helpers";
import { setNotify } from "../../../../redux/utils";

import axios from "axios";
import { removeBookingCodeRequests } from "../../../../redux/restaurant";

export default function DismissBookingRequest({ table }) {
  const { restaurant } = useSelector((state) => state.restaurant);

  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    setSaving(true);
    axios({
      method: "POST",
      url: `${DOMAIN}/orders/sessions/dismiss/`,
      headers: getAuthorizationHeader(),
      params: {
        organization_uid: restaurant.uid,
      },
      data: {
        session_id: table.sessionId,
      },
    })
      .then(() => {
        dispatch(removeBookingCodeRequests({ table_id: table.id }));
      })
      .catch((err) => {
        setOpen(false);
        setSaving(false);
        let errorMessage = "Something went Wrong!";
        try {
          errorMessage = JSON.parse(err.request.response).message;
        } catch (error) {}
        dispatch(
          setNotify({
            open: true,
            action: errorMessage,
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      });
  };

  return (
    <>
      <IconButton size="sm" color="danger" onClick={() => setOpen(true)}>
        <Close />
      </IconButton>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          px: 1,
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 500,
            minWidth: isMobile() ? "auto" : 340,
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Stack spacing={1}>
            <Typography
              component="h2"
              id="modal-title"
              level="h4"
              fontWeight="lg"
            >
              Dismiss Booking Request?
            </Typography>
            <Typography level="body-xs" textColor="neutral.400">
              Are you sure you want to dismiss booking request from{" "}
              <b>{table.bookingCustomerName || table.bookingCustomerPhone}</b>{" "}
              on <b>{table.name}</b>?
            </Typography>
            <Stack spacing={2}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  color="neutral"
                  disabled={saving}
                  size="sm"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  color="danger"
                  disabled={saving}
                  size="sm"
                  onClick={handleSubmit}
                >
                  Dismiss
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Sheet>
      </Modal>
    </>
  );
}

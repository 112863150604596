import { Stack, Sheet } from "@mui/joy";
import FilterRowRight from "./filterRowRight";

export default function EditorWrapper({ children }) {
  return (
    <Stack
      spacing={1}
      sx={{
        position: "sticky",
        top: "10px",
        height: "98vh",
        overflow: "scroll",
      }}
    >
      <FilterRowRight />
      <Sheet
        sx={{
          bgcolor: "#ffffff",
          borderRadius: "6px",
        }}
      >
        <Stack boxShadow="xs" px={2.5} py={1.75} minHeight="90vh">
          {children}
        </Stack>
      </Sheet>
    </Stack>
  );
}

import { useNavigate } from "react-router-dom";

import { Button, IconButton, Stack } from "@mui/joy";

import { ArrowBackIosNew, Logout } from "@mui/icons-material";

import { deleteUserCredentials } from "../../../utils/helpers";
import urls from "../../../utils/urls.json";

export default function Header() {
  const navigate = useNavigate();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      bgcolor="#ffffff"
      boxShadow="sm"
      sx={{
        padding: {
          xs: "10px 15px",
          lg: "15px 35px",
        },
      }}
    >
      <IconButton onClick={() => navigate(-1)}>
        <ArrowBackIosNew />
      </IconButton>
      <Button
        variant="outlined"
        color="danger"
        size="sm"
        endDecorator={<Logout fontSize="small" />}
        onClick={() => {
          deleteUserCredentials();
          window.location.replace(urls.auth.children.login.absolutePath);
        }}
      >
        Log out
      </Button>
    </Stack>
  );
}

import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { setActivePathId } from "../../../../redux/utils";

import urls from "../../../../utils/urls.json";
import BodyWrapper from "../components/bodyWrapper";
import Filters from "./filters";
import Body from "./body";

export default function Summary() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActivePathId(urls.restaurant.pathId));
  }, [dispatch]);

  return (
    <BodyWrapper withStack>
      <Filters />
      <Body />
    </BodyWrapper>
  );
}

import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setActivePathId, setNotify } from "../../../../redux/utils";

import BodyWrapper from "../components/bodyWrapper";
import Plans from "./plans";
import Payments from "./payments";

import { getAuthorizationHeader } from "../../../../utils/helpers";
import urls from "../../../../utils/urls.json";
import { DOMAIN } from "../../../../utils/config";

import axios from "axios";
import Loading from "../../../../hoc/loading";

export default function Billing() {
  const { restaurant } = useSelector((state) => state.restaurant);

  const [payments, setPayments] = useState([]);
  const [plans, setPlans] = useState([]);

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActivePathId(urls.restaurant.children.billing.pathId));
  }, [dispatch]);

  useEffect(() => {
    Promise.all([
      axios({
        method: "GET",
        url: `${DOMAIN}/billings/payments/`,
        headers: getAuthorizationHeader(),
        params: {
          organization_uid: restaurant.uid,
        },
      }),
      axios({
        method: "GET",
        url: `${DOMAIN}/billings/plans/`,
        headers: getAuthorizationHeader(),
        params: {
          currency: restaurant.currency,
        },
      }),
    ])
      .then(([paymentsRes, plansRes]) => {
        setLoading(false);
        setPayments(paymentsRes.data);
        setPlans(plansRes.data);
      })
      .catch(() => {
        dispatch(
          setNotify({
            open: true,
            action: "Something went wrong!",
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      });
  }, [restaurant, dispatch]);

  return (
    <BodyWrapper withStack>
      <Loading loading={loading} minHeight={"50vh"}>
        <Plans plans={plans} />
        <Payments payments={payments} />
      </Loading>
    </BodyWrapper>
  );
}

import { useCallback, useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import {
  setItems,
  setVariants as setAllVariants,
  setMenuEditor,
} from "../../../../../redux/restaurant";
import { setNotify } from "../../../../../redux/utils";

import {
  Stack,
  Typography,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Switch,
  Grid,
  Select,
  Option,
  IconButton,
} from "@mui/joy";

import {
  Add,
  Save,
  Circle,
  Close,
  Check,
  ArrowBackIosNew,
} from "@mui/icons-material";

import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCorners,
  useSensor,
  useSensors,
} from "@dnd-kit/core";

import {
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";

import axios from "axios";

import {
  getAuthorizationHeader,
  getTagIcon,
} from "../../../../../utils/helpers";
import { DOMAIN } from "../../../../../utils/config";
import EditorWrapper from "../editorWrapper";
import Variants from "./variants";
import MoreSettings from "./moreSettings";
import AddVariant from "./addVariant";

export default function EditItem() {
  const {
    restaurant,
    menuEditor,
    items,
    itemCategories,
    variants: allVariants,
    tags,
  } = useSelector((state) => state.restaurant);

  const getItem = useCallback(() => {
    return items.filter((item) => item.id === menuEditor.entryId)[0];
  }, [items, menuEditor]);

  const getItemVariants = useCallback(() => {
    return allVariants.filter((v) => v.item === getItem().id);
  }, [allVariants, getItem]);

  const getItemTags = useCallback(() => {
    let selectedTags = [];
    getItem().tags.forEach((tId) => {
      selectedTags = [...selectedTags, ...tags.filter((i) => i.id === tId)];
    });
    return selectedTags;
  }, [getItem, tags]);

  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [category, setCategory] = useState(null);
  const [inStock, setInStock] = useState(null);
  const [isVeg, setIsVeg] = useState(null);
  const [variants, setVariants] = useState(null);
  const [selectedTags, setSelectedTags] = useState(null);

  const [addVariant, setAddVariant] = useState(false);

  const [ready, setReady] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (
      (name === null) &
      (description === null) &
      (category === null) &
      (inStock === null) &
      (isVeg === null) &
      (variants === null) &
      (selectedTags === null) &
      (ready === false)
    ) {
      setName(getItem().name);
      setDescription(getItem().description);
      setCategory(getItem().category);
      setInStock(getItem().is_live);
      setIsVeg(getItem().is_veg);
      setVariants(getItemVariants());
      setSelectedTags(getItemTags());
      setReady(true);
    }
  }, [
    name,
    description,
    category,
    inStock,
    isVeg,
    variants,
    selectedTags,
    ready,

    setName,
    setDescription,
    setCategory,
    setInStock,
    setIsVeg,
    setVariants,
    setSelectedTags,
    setReady,

    getItem,
    getItemVariants,
    getItemTags,
  ]);

  const dispatch = useDispatch();

  const handleSave = (e) => {
    e.preventDefault();

    const item = getItem();
    const prevVariants = getItemVariants();
    const prevTags = getItemTags();

    setSaving(true);
    let payload = {};

    if (name !== item.name) {
      payload["name"] = name;
    }
    if (description !== item.description) {
      payload["description"] = description;
    }
    if (category !== item.category) {
      payload["category"] = category;
    }
    if (inStock !== item.is_live) {
      payload["is_live"] = inStock;
    }
    if (isVeg !== item.is_veg) {
      payload["is_veg"] = isVeg;
    }
    if (JSON.stringify(variants) !== JSON.stringify(prevVariants)) {
      payload["variants"] = variants;
    }
    if (JSON.stringify(selectedTags) !== JSON.stringify(prevTags)) {
      payload["tags"] = selectedTags.map((i) => i.id);
    }

    axios({
      method: "PATCH",
      url: `${DOMAIN}/menus/items/`,
      headers: getAuthorizationHeader(),
      params: {
        uid: restaurant.uid,
        id: item.id,
      },
      data: payload,
    })
      .then((res) => {
        setSaving(false);
        dispatch(
          setItems(
            items.map((i) => {
              if (i.id === res.data.id) {
                return {
                  ...i,
                  ...res.data,
                };
              }
              return i;
            })
          )
        );
        const otherItemVariants = allVariants.filter((v) => v.item !== item.id);
        dispatch(setAllVariants([...otherItemVariants, ...res.data.variants]));

        dispatch(
          setNotify({
            open: true,
            action: "Changes Saved!",
            severity: "success",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      })
      .catch((err) => {
        setSaving(false);
        let errorMessage = "Something went Wrong!";
        try {
          errorMessage = JSON.parse(err.request.response).message;
        } catch (error) {}
        dispatch(
          setNotify({
            open: true,
            action: errorMessage,
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      });
  };

  const getUnselectedTags = () => {
    return tags.filter((t) => !selectedTags.includes(t));
  };

  const handleUnselectTag = (tag) => {
    setSelectedTags(selectedTags.filter((t) => t.id !== tag.id));
  };

  const handleSelectTag = (tag) => {
    setSelectedTags([...selectedTags, tag]);
  };

  const resetState = () => {
    const item = getItem();
    setName(item.name);
    setDescription(item.description);
    setCategory(item.category);
    setInStock(item.is_live);
    setIsVeg(item.is_veg);
    setVariants(getItemVariants());
    setSelectedTags(getItemTags());

    setSaving(false);
  };

  const handleDragEnd = (event) => {
    const item = getItem();
    const itemVariants = getItemVariants();
    const otherVariants = allVariants.filter((v) => v.item !== item.id);

    let backup = itemVariants.map((i) => i);

    const { active, over } = event;

    if (active.id === over.id) {
      return;
    }

    const activeInstance = itemVariants.filter(
      (i) => i.seq_no === active.id
    )[0];
    const overInstance = itemVariants.filter((i) => i.seq_no === over.id)[0];

    const seqNoUpgraded = active.id < over.id;
    const operation = seqNoUpgraded ? -1 : 1;

    let updatedArray = [];
    if (seqNoUpgraded) {
      updatedArray = itemVariants.map((i) => {
        if (i.id === activeInstance.id) {
          return {
            ...i,
            seq_no: over.id,
          };
        } else if ((i.seq_no > active.id) & (i.seq_no <= over.id)) {
          return {
            ...i,
            seq_no: i.seq_no + operation,
          };
        }
        return i;
      });
    } else {
      updatedArray = itemVariants.map((i) => {
        if (i.id === activeInstance.id) {
          return {
            ...i,
            seq_no: over.id,
          };
        } else if ((i.seq_no >= over.id) & (i.seq_no < active.id)) {
          return {
            ...i,
            seq_no: i.seq_no + operation,
          };
        }
        return i;
      });
    }
    dispatch(setAllVariants([...updatedArray, ...otherVariants]));
    axios({
      method: "POST",
      url: `${DOMAIN}/menus/item-variants/rearrange/`,
      headers: getAuthorizationHeader(),
      params: {
        uid: restaurant.uid,
        item_id: item.id,
      },
      data: {
        active_id: activeInstance.id,
        over_id: overInstance.id,
      },
    })
      .then((res) =>
        dispatch(
          setNotify({
            open: true,
            action: "Order Saved!",
            severity: "success",
            autoHideDuration: 2000,
            vertical: "bottom",
            horizontal: "right",
          })
        )
      )
      .catch((err) => {
        dispatch(setAllVariants([...backup, ...otherVariants]));
      });
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const getSeqWiseVariants = () => {
    let itemVariantsCopy = variants.map((i) => i);
    return itemVariantsCopy.sort((a, b) => a.seq_no - b.seq_no);
  };

  const setCurrentlyEditingCategory = () => {
    const item = getItem();
    dispatch(
      setMenuEditor({
        currentlyEditing: "category",
        entryId: item.category,
        saving: false,
      })
    );
  };

  return (
    <EditorWrapper>
      {ready ? (
        <form onSubmit={handleSave}>
          <Stack spacing={3}>
            {/* Header */}
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" alignItems="center" spacing={0.75}>
                <IconButton
                  size="sm"
                  variant="plain"
                  onClick={setCurrentlyEditingCategory}
                >
                  <ArrowBackIosNew />
                </IconButton>
                <Circle
                  fontSize="md"
                  sx={{ color: isVeg ? "#00B620" : "#F90202" }}
                />
                <Typography level="title-lg" fontWeight={700}>
                  {name || "Item"}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={2}>
                {/* <Button
                  variant="outlined"
                  color="neutral"
                  size="sm"
                  startDecorator={<Undo />}
                  onClick={resetState}
                >
                  Undo
                </Button> */}
                <Button
                  variant="solid"
                  color="neutral"
                  size="sm"
                  startDecorator={<Save />}
                  disabled={saving}
                  type="submit"
                >
                  {saving ? "Saving" : "Save"}
                </Button>
                <MoreSettings resetState={resetState} item={getItem()} />
              </Stack>
            </Stack>
            {/* Basic Details */}
            <Stack spacing={2}>
              <Typography level="body-md">Basic Details</Typography>
              <Grid container rowGap={2}>
                <Grid xs={6}>
                  <FormControl>
                    <FormLabel>Name</FormLabel>
                    <Input
                      required
                      size="sm"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid xs={6}>
                  <Stack direction="row" justifyContent="flex-end">
                    <FormControl>
                      <FormLabel>In Stock</FormLabel>
                      <Switch
                        size="sm"
                        color="neutral"
                        checked={inStock}
                        variant={inStock ? "solid" : "outlined"}
                        onChange={() => setInStock(!inStock)}
                      />
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid xs={12}>
                  <FormControl>
                    <FormLabel>Description</FormLabel>
                    <Textarea
                      minRows={2}
                      maxRows={3}
                      size="sm"
                      variant="soft"
                      placeholder="Add a short description about the item."
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid xs={6}>
                  <FormControl>
                    <FormLabel>Category</FormLabel>
                    <Select
                      size="sm"
                      value={category}
                      onChange={(_, newValue) => setCategory(newValue)}
                    >
                      {itemCategories.map((category, index) => (
                        <Option value={category.id} key={index}>
                          {category.name}
                        </Option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={6} paddingLeft={2}>
                  <FormControl>
                    <FormLabel>Veg/Non-veg</FormLabel>
                    <Select
                      size="sm"
                      value={isVeg}
                      onChange={() => setIsVeg(!isVeg)}
                    >
                      <Option value={true}>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Circle fontSize="small" sx={{ color: "#00B620" }} />
                          <Typography level="body-sm">Veg</Typography>
                        </Stack>
                      </Option>
                      <Option value={false}>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Circle fontSize="small" sx={{ color: "#F90202" }} />
                          <Typography level="body-sm">Non-veg</Typography>
                        </Stack>
                      </Option>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Stack>
            {/* Variants */}
            <Stack spacing={2}>
              <Typography level="body-md">
                Variations ({variants.length})
              </Typography>
              <DndContext
                onDragEnd={handleDragEnd}
                sensors={sensors}
                collisionDetection={closestCorners}
              >
                <SortableContext
                  items={getSeqWiseVariants().map((i) => i.seq_no)}
                >
                  <Stack spacing={2}>
                    {getSeqWiseVariants().map((variant, index) => (
                      <Variants
                        variant={variant}
                        variants={variants}
                        setVariants={setVariants}
                        key={index}
                      />
                    ))}
                    {addVariant ? (
                      <AddVariant
                        item={getItem()}
                        setAddVariant={setAddVariant}
                      />
                    ) : null}
                  </Stack>
                </SortableContext>
              </DndContext>

              <Stack direction="row" justifyContent="flex-end">
                <Button
                  variant="outlined"
                  color="neutral"
                  size="sm"
                  startDecorator={<Add />}
                  onClick={() => setAddVariant(true)}
                  disabled={addVariant}
                >
                  Add variation
                </Button>
              </Stack>
            </Stack>
            {/* Tags */}
            <Stack spacing={2}>
              <Grid container>
                <Grid xs={6}>
                  <Stack spacing={1}>
                    <Typography level="title-sm">
                      <b>Selected Tags</b> ({selectedTags.length})
                    </Typography>
                    {selectedTags.length ? (
                      selectedTags.map((tag, index) => (
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          key={index}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            {getTagIcon(tag)}
                            <Typography level="body-xs">{tag.name}</Typography>
                          </Stack>
                          <IconButton
                            size="sm"
                            onClick={() => handleUnselectTag(tag)}
                          >
                            <Close />
                          </IconButton>
                        </Stack>
                      ))
                    ) : (
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        minHeight="200px"
                      >
                        <Typography level="body-xs">
                          No Tags Selected
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                </Grid>
                <Grid xs={6} paddingLeft={2}>
                  <Stack spacing={1}>
                    <Typography level="title-sm">
                      <b>Available Tags</b>
                    </Typography>
                    {getUnselectedTags().length ? (
                      getUnselectedTags().map((tag, index) => (
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          key={index}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            {getTagIcon(tag)}
                            <Typography level="body-xs">{tag.name}</Typography>
                          </Stack>
                          <IconButton
                            size="sm"
                            onClick={() => handleSelectTag(tag)}
                          >
                            <Check />
                          </IconButton>
                        </Stack>
                      ))
                    ) : (
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        minHeight="200px"
                      >
                        <Typography level="body-xs">
                          All Tags Selected
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        </form>
      ) : null}
    </EditorWrapper>
  );
}

import { useState } from "react";

import { useDispatch } from "react-redux";

import { useParams } from "react-router-dom";

import { Stack, Button, IconButton, Modal, Typography, Sheet } from "@mui/joy";

import { RoomService } from "@mui/icons-material";

import { getAuthorizationHeader } from "../../../../../../utils/helpers";
import { DOMAIN, isMobile } from "../../../../../../utils/config";
import { setNotify } from "../../../../../../redux/utils";

import axios from "axios";

export default function CallingRequest({ table, sessionId }) {
  const [open, setOpen] = useState(false);
  const [sending, setSending] = useState(false);

  const { restaurantId } = useParams();

  const dispatch = useDispatch();

  const handleRequest = () => {
    setSending(true);
    axios({
      method: "POST",
      url: `${DOMAIN}/orders/call-acknowledge/`,
      headers: getAuthorizationHeader(),
      params: {
        organization_uid: restaurantId,
      },
      data: {
        session_id: sessionId,
      },
    })
      .then(() => {
        setOpen(false);
        dispatch(
          setNotify({
            open: true,
            action: "Saved!",
            severity: "success",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "center",
          })
        );
      })
      .catch((err) => {
        setSending(false);
        setOpen(false);
        let errorMessage = "Something went Wrong!";
        try {
          errorMessage = JSON.parse(err.request.response).message;
        } catch (error) {}
        dispatch(
          setNotify({
            open: true,
            action: errorMessage,
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "center",
          })
        );
      });
  };

  return (
    <>
      {isMobile() ? (
        <IconButton
          color="danger"
          variant="solid"
          size="sm"
          onClick={() => setOpen(true)}
        >
          <RoomService />
        </IconButton>
      ) : (
        <Button
          color="danger"
          variant="solid"
          startDecorator={<RoomService />}
          size="sm"
          onClick={() => setOpen(true)}
        >
          Calling
        </Button>
      )}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{
            minWidth: "300px",
            borderRadius: "md",
            p: 3,
          }}
        >
          <Stack spacing={3}>
            <Stack spacing={1}>
              <Typography level="title-sm">
                <b>{table.name}</b> Need Assistance
              </Typography>
              <Typography level="body-xs" textColor="neutral.400">
                Have you fulfilled {table.name}'s request?
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1.5}
            >
              <Button
                fullWidth
                color="neutral"
                variant="outlined"
                disabled={sending}
                size="sm"
                onClick={() => setOpen(false)}
              >
                No
              </Button>
              <Button
                fullWidth
                color="neutral"
                disabled={sending}
                size="sm"
                onClick={handleRequest}
              >
                Yes
              </Button>
            </Stack>
          </Stack>
        </Sheet>
      </Modal>
    </>
  );
}

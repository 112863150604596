import { useSelector } from "react-redux";

import { Stack, Input, Textarea, Switch, Grid, IconButton } from "@mui/joy";

import { Menu as MenuIcon } from "@mui/icons-material";

import { useSortable } from "@dnd-kit/sortable";
import DeleteVariant from "./deleteVariant";
import { getCurrencyIcon } from "../../../../../utils/config";

export default function Variants({ variant, variants, setVariants }) {
  const { restaurant } = useSelector((state) => state.restaurant);

  const { attributes, listeners, setNodeRef, transform } = useSortable({
    id: variant.seq_no,
  });

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  return (
    <Grid container style={style} {...attributes} rowSpacing={1}>
      <Grid xs={1}>
        <IconButton size="sm" ref={setNodeRef} {...listeners}>
          <MenuIcon fontSize="small" />
        </IconButton>
      </Grid>
      <Grid xs={5}>
        <Stack direction="row" alignItems="flex-end" spacing={1}>
          <Input
            fullWidth
            required
            size="sm"
            type="text"
            placeholder="eg. Regular"
            value={variant.name}
            onChange={(e) => {
              const updatedVariants = variants.map((v) => {
                if (v.id === variant.id) {
                  return {
                    ...variant,
                    name: e.target.value,
                  };
                }
                return v;
              });
              setVariants(updatedVariants);
            }}
          />
        </Stack>
      </Grid>
      <Grid xs={6} paddingLeft={2}>
        <Stack direction="row" alignItems="flex-end" spacing={2}>
          <Input
            fullWidth
            required
            size="sm"
            type="text"
            placeholder="Enter Price"
            startDecorator={getCurrencyIcon(restaurant.currency)}
            value={variant.price}
            onChange={(e) => {
              const updatedVariants = variants.map((v) => {
                if (v.id === variant.id) {
                  return {
                    ...variant,
                    price: e.target.value,
                  };
                }
                return v;
              });
              setVariants(updatedVariants);
            }}
          />
          <Stack direction="row" spacing={1}>
            <Switch
              size="sm"
              color="neutral"
              checked={variant.is_live}
              variant={variant.is_live ? "solid" : "outlined"}
              onChange={() => {
                const updatedVariants = variants.map((v) => {
                  if (v.id === variant.id) {
                    return {
                      ...variant,
                      is_live: !variant.is_live,
                    };
                  }
                  return v;
                });
                setVariants(updatedVariants);
              }}
            />
            <DeleteVariant variant={variant} />
          </Stack>
        </Stack>
      </Grid>
      <Grid
        xs={1}
        sx={{ display: variants.length > 1 ? "block" : "none" }}
      ></Grid>
      <Grid xs={11} sx={{ display: variants.length > 1 ? "block" : "none" }}>
        <Textarea
          size="sm"
          minRows={1}
          maxRows={2}
          placeholder="Variant description (optional)"
          variant="soft"
          value={variant.description || ""}
          onChange={(e) => {
            const updatedVariants = variants.map((v) => {
              if (v.id === variant.id) {
                return {
                  ...variant,
                  description: e.target.value,
                };
              }
              return v;
            });
            setVariants(updatedVariants);
          }}
        />
      </Grid>
    </Grid>
  );
}

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import {
  AccessTime,
  AttachMoney,
  ShoppingCartOutlined,
  Person,
} from "@mui/icons-material";

import { Stack, Typography, Grid } from "@mui/joy";

import moment from "moment";
import ServeOrder from "./serveOrder";
import OrderDetails from "./orderDetails";
import { getCurrency } from "../../../../../utils/config";

export default function Order({ order }) {
  const { restaurant, tables, sessions, pendingOrders, orders, customers } =
    useSelector((state) => state.restaurant);

  const [loaded, setLoaded] = useState(false);
  const [table, setTable] = useState({});
  const [orderSeq, setOrderSeq] = useState(null);
  const [customer, setCustomer] = useState({});

  useEffect(() => {
    const sessionList = sessions.filter((i) => i.id === order.session);
    if (!sessionList.length) {
      return;
    }
    const session = sessionList[0];

    const tableList = tables.filter((i) => i.id === session.table);
    if (!tableList.length) {
      return;
    }
    const table = tableList[0];
    const tableOrders = [...orders, ...pendingOrders].filter(
      (i) => i.session === session.id
    );
    const customer = customers.filter((i) => i.id === session.customer)[0];

    setCustomer(customer);
    setTable(table);

    tableOrders.sort((a, b) => a.id - b.id);
    const tableOrderIds = tableOrders.map((i) => i.id);

    setOrderSeq(tableOrderIds.indexOf(order.id) + 1);

    setLoaded(true);
  }, [
    pendingOrders,
    orders,
    loaded,
    tables,
    order.id,
    order.session,
    sessions,
    customers,
  ]);

  return loaded ? (
    <Grid xs={12} sm={6} md={4} xl={3}>
      <Stack
        border={`1px solid ${
          order.completed_on
            ? "#EEEEEE"
            : order.served_on
            ? "#00B620"
            : "#F7B200"
        }`}
        boxShadow={`0px 0px 10px 0px ${
          order.completed_on
            ? "rgbs(238, 238, 238, 0.25)"
            : order.served_on
            ? "rgba(0, 182, 32, 0.25)"
            : "rgba(247, 178, 0, 0.25)"
        }`}
        bgcolor="#fff"
        borderRadius="6px"
        px={2}
        py={1}
        spacing={1}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            minHeight: "35px",
          }}
        >
          <Typography level="title-md" fontWeight={700}>
            {table.name}
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            {orderSeq && (
              <Typography level="title-md" fontWeight={700}>
                Order #{orderSeq}
              </Typography>
            )}
          </Stack>
        </Stack>
        <Grid container rowGap={1}>
          <Grid xs={6}>
            <Stack spacing={0.25}>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <ShoppingCartOutlined fontSize="small" />
                <Typography level="body-sm" textColor="neutral.400">
                  Items:
                </Typography>
                <Typography level="title-sm" fontWeight={700}>
                  {order.order_items
                    .map((i) => i.qty)
                    .reduce((partialSum, a) => partialSum + a, 0)}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <AttachMoney fontSize="small" />
                <Typography level="body-sm" textColor="neutral.400">
                  Amount:
                </Typography>
                <Typography level="title-sm" fontWeight={700}>
                  {getCurrency(restaurant.currency)}
                  {order.total_amount}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid xs={6}>
            <Stack spacing={0.25}>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <Person fontSize="small" />
                <Typography level="title-sm">
                  {customer.name || customer.phone}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <AccessTime fontSize="small" />
                  {order.completed_on ? (
                    <Typography
                      level="body-xs"
                      textColor="neutral.400"
                      fontWeight={700}
                    >
                      Billed
                    </Typography>
                  ) : order.served_on ? (
                    <Typography
                      level="body-xs"
                      textColor="neutral.400"
                      fontWeight={700}
                    >
                      Served
                    </Typography>
                  ) : (
                    <Typography
                      level="body-xs"
                      textColor="neutral.400"
                      fontWeight={700}
                    >
                      Placed
                    </Typography>
                  )}
                  <Typography
                    level="body-xs"
                    textColor="neutral.500"
                    fontWeight={700}
                  >
                    {moment(
                      order.completed_on
                        ? order.completed_on
                        : order.served_on
                        ? order.served_on
                        : order.placed_on
                    ).format("LT")}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Stack direction="row" spacing={order.served_on ? 0 : 2}>
          <ServeOrder order={order} orderSeq={orderSeq} table={table} />
          <OrderDetails
            order={order}
            orderSeq={orderSeq}
            table={table}
            customer={customer}
          />
        </Stack>
      </Stack>
    </Grid>
  ) : null;
}

import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setMembers } from "../../../../../redux/restaurant";
import { setNotify } from "../../../../../redux/utils";

import { Edit } from "@mui/icons-material";
import {
  Stack,
  Modal,
  ModalClose,
  Typography,
  Sheet,
  Divider,
  IconButton,
  Button,
  Checkbox,
  Select,
  Option,
  Chip,
  ChipDelete,
} from "@mui/joy";

import { getAuthorizationHeader } from "../../../../../utils/helpers";
import { DOMAIN } from "../../../../../utils/config";
import DeleteMember from "./deleteMember";

import axios from "axios";

export default function EditMember({ member }) {
  const { accessCategory, restaurant, members } = useSelector(
    (state) => state.restaurant
  );

  const getAccessOptions = () => {
    let options = [];
    accessCategory.forEach((i) => {
      i.accesses.forEach((j) => {
        options = [...options, j];
      });
    });
    return options;
  };

  const getDefaultAccess = () => {
    const options = getAccessOptions();
    const values = member.additional_accesses.map(
      (accessId) => options.filter((o) => o.id === accessId)[0]
    );
    return values;
  };

  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [group, setGroup] = useState(member.group);
  const [isBlocked, setIsBlocked] = useState(member.is_login_blocked);
  const [selectedAccesses, setSelectedAccesses] = useState(getDefaultAccess());

  const dispatch = useDispatch();

  const resetState = () => {
    setGroup(member.group);
    setIsBlocked(member.is_login_blocked);
    setSelectedAccesses(getDefaultAccess());
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let payload = {};
    if (group !== member.group) {
      payload["group"] = group;
    }
    if (isBlocked !== member.is_login_blocked) {
      payload["is_login_blocked"] = isBlocked;
    }
    payload["additional_accesses"] = selectedAccesses.map((i) => i.id);

    setSaving(true);
    axios({
      method: "PATCH",
      url: `${DOMAIN}/organizations/members/`,
      headers: getAuthorizationHeader(),
      params: {
        organization_uid: restaurant.uid,
        id: member.id,
      },
      data: payload,
    })
      .then((res) => {
        setSaving(false);
        dispatch(
          setMembers(
            members.map((i) => {
              if (i.id === member.id) return res.data;
              return i;
            })
          )
        );
        dispatch(
          setNotify({
            open: true,
            action: "Saved!",
            severity: "success",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      })
      .catch((err) => {
        setSaving(false);
        let errorMessage = "Something went Wrong!";
        try {
          errorMessage = JSON.parse(err.request.response).message;
        } catch (error) {}
        dispatch(
          setNotify({
            open: true,
            action: errorMessage,
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      });
  };

  const isAccessSelected = (access) => {
    return Boolean(selectedAccesses.filter((i) => i.id === access.id).length);
  };

  return (
    <>
      <IconButton size="sm" color="neutral" onClick={() => setOpen(true)}>
        <Edit fontSize="small" />
      </IconButton>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 500,
            minWidth: 500,
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} onClick={resetState} />
          <Stack mb={2}>
            <Typography
              component="h2"
              id="modal-title"
              level="h4"
              fontWeight="lg"
            >
              Edit {member.user_details.first_name}{" "}
              {member.user_details.last_name}
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Stack spacing={1}>
              <Typography level="title-sm">Group and Permissions</Typography>
              <form onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <Select
                    value={group}
                    onChange={(_, newValue) => {
                      setGroup(newValue);
                    }}
                  >
                    {restaurant.access_groups.map((group, index) => (
                      <Option value={group.id} key={index}>
                        {group.name}
                      </Option>
                    ))}
                  </Select>
                  <Stack spacing={1}>
                    <Stack direction="row" flexWrap="wrap">
                      {selectedAccesses.map((access, index) => (
                        <div
                          key={index}
                          style={{
                            padding: "2px 4px",
                          }}
                        >
                          <Chip
                            size="sm"
                            variant="soft"
                            color="neutral"
                            endDecorator={
                              <ChipDelete
                                onDelete={() =>
                                  setSelectedAccesses(
                                    selectedAccesses.filter(
                                      (i) => i.id !== access.id
                                    )
                                  )
                                }
                              />
                            }
                          >
                            {access.title}
                          </Chip>
                        </div>
                      ))}
                    </Stack>
                    <Select
                      value={0}
                      onChange={(_, newValue) => {
                        const value = getAccessOptions().filter(
                          (o) => o.id === newValue
                        )[0];
                        setSelectedAccesses([...selectedAccesses, value]);
                      }}
                    >
                      <Option value={0} disabled>
                        Select Additional Permissions
                      </Option>
                      {getAccessOptions().map((access, i) => (
                        <Option
                          key={i}
                          value={access.id}
                          disabled={isAccessSelected(access)}
                        >
                          {access.title}
                        </Option>
                      ))}
                    </Select>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Checkbox
                        size="sm"
                        checked={isBlocked}
                        onChange={() => setIsBlocked(!isBlocked)}
                      />
                      <Typography level="body-xs">
                        Block from Restaurant
                      </Typography>
                    </Stack>
                    <Button
                      color="neutral"
                      size="sm"
                      type="submit"
                      disabled={saving}
                    >
                      Save
                    </Button>
                  </Stack>
                </Stack>
              </form>
            </Stack>
            <Divider />
            <Stack spacing={1}>
              <Typography level="title-sm">Danger Zone</Typography>
              <Stack direction="row" justifyContent="flex-end">
                <DeleteMember member={member} />
              </Stack>
            </Stack>
          </Stack>
        </Sheet>
      </Modal>
    </>
  );
}

import { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { setActivePathId } from "../../../../redux/utils";

import { Stack, Grid } from "@mui/joy";

import urls from "../../../../utils/urls.json";
import BodyWrapper from "../components/bodyWrapper";

import CategoriesSection from "./categoriesSection";
import FilterRowLeft from "./filterRowLeft";
import ItemsSection from "./itemsSection";

import EditItem from "./editItem";
import EditorPlaceholder from "./editorPlaceholder";
import EditCategory from "./editCategory";

export default function Menu() {
  const { menuEditor } = useSelector((state) => state.restaurant);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActivePathId(urls.restaurant.children.menu.pathId));
  }, [dispatch]);

  const GetCurrentEditorScreen = () => {
    switch (menuEditor.currentlyEditing) {
      case "category":
        return <EditCategory />;
      case "item":
        return <EditItem />;
      default:
        return <EditorPlaceholder />;
    }
  };

  return (
    <BodyWrapper withStack>
      <Stack spacing={2.5}>
        <Grid container>
          <Grid xs={12} md={6} paddingRight="17.5px">
            <Stack spacing={2}>
              <FilterRowLeft />
              <CategoriesSection />
              <ItemsSection />
            </Stack>
          </Grid>
          <Grid xs={12} md={6} paddingLeft="17.5px">
            <GetCurrentEditorScreen />
          </Grid>
        </Grid>
      </Stack>
    </BodyWrapper>
  );
}

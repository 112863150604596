import { Stack, Typography } from "@mui/joy";

import { AdsClick } from "@mui/icons-material";

import EditorWrapper from "./editorWrapper";

export default function EditorPlaceholder() {
  return (
    <EditorWrapper>
      <Stack
        sx={{
          position: "absolute",
          top: "45%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        spacing={2}
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <AdsClick
          sx={{
            height: "54px",
            width: "54px",
          }}
        />
        <Typography level="body-sm">
          Please select a Category or an Item <br /> to start editing
        </Typography>
      </Stack>
    </EditorWrapper>
  );
}

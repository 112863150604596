import { useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { setNotify } from "../../../../redux/utils";

import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalDialog,
  DialogTitle,
  DialogContent,
  Stack,
} from "@mui/joy";

import { Add } from "@mui/icons-material";

import axios from "axios";

import { DOMAIN } from "../../../../utils/config";
import { getAuthorizationHeader } from "../../../../utils/helpers";
import { setItemCategories, setMenuEditor } from "../../../../redux/restaurant";

export default function AddCategory() {
  const { restaurant, itemCategories } = useSelector(
    (state) => state.restaurant
  );

  const [open, setOpen] = useState(false);

  const [name, setName] = useState("");
  const [saving, setSaving] = useState(false);

  const dispatch = useDispatch();

  const handleSave = (e) => {
    e.preventDefault();
    setSaving(true);
    axios({
      method: "POST",
      url: `${DOMAIN}/menus/item-categories/`,
      headers: getAuthorizationHeader(),
      params: {
        uid: restaurant.uid,
      },
      data: {
        name,
      },
    })
      .then((res) => {
        setSaving(false);
        setName("");
        dispatch(setItemCategories([...itemCategories, res.data]));
        dispatch(
          setNotify({
            open: true,
            action: "Category Created!",
            severity: "success",
            autoHideDuration: 2000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
        dispatch(
          setMenuEditor({
            currentlyEditing: "category",
            entryId: res.data.id,
            saving: false,
          })
        );
        setOpen(false);
      })
      .catch((err) => {
        setSaving(false);
        let errorMessage = "Something went Wrong!";
        try {
          errorMessage = JSON.parse(err.request.response).message;
        } catch (error) {}
        dispatch(
          setNotify({
            open: true,
            action: errorMessage,
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      });
  };

  return (
    <>
      <Button
        variant="outlined"
        color="neutral"
        size="sm"
        startDecorator={<Add />}
        onClick={() => setOpen(true)}
      >
        Add Category
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog minWidth="380px">
          <DialogTitle>Create New Category</DialogTitle>
          <DialogContent>
            Fill in the information of the Category.
          </DialogContent>
          <form onSubmit={handleSave}>
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  size="sm"
                  autoFocus
                  required
                  placeholder="Eg. Veg Pizzas"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
              <Button type="submit" color="neutral" size="sm" disabled={saving}>
                {saving ? "Creating" : "Create"}
              </Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </>
  );
}

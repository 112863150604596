import { useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import {
  setItemCategories,
  setMenuEditor,
} from "../../../../../redux/restaurant";

import {
  Stack,
  Typography,
  Button,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/joy";

import {
  Menu as MenuIcon,
  Save,
  Circle,
  DriveFileRenameOutline,
} from "@mui/icons-material";

import axios from "axios";

import { DOMAIN } from "../../../../../utils/config";
import { getAuthorizationHeader } from "../../../../../utils/helpers";
import EditorWrapper from "../editorWrapper";
import { setNotify } from "../../../../../redux/utils";
import MoreSettings from "./moreSettings";

export default function EditCategory() {
  const { restaurant, menuEditor, itemCategories, items, variants } =
    useSelector((state) => state.restaurant);

  const getCategory = () => {
    return itemCategories.filter((ic) => ic.id === menuEditor.entryId)[0];
  };

  const getCategoryItems = () => {
    const categoryItems = items.filter(
      (item) => item.category === menuEditor.entryId
    );
    return categoryItems
      .map((item) => ({
        ...item,
        variants: variants.filter((v) => v.item === item.id),
      }))
      .sort((a, b) => a.id - b.id);
  };

  const [name, setName] = useState(getCategory().name || "");
  const [inStock, setInStock] = useState(getCategory().is_live || false);

  const dispatch = useDispatch();

  const handleSave = (e) => {
    e.preventDefault();

    const category = getCategory();

    dispatch(
      setMenuEditor({
        ...menuEditor,
        saving: true,
      })
    );

    let payload = {};

    if (name !== category.name) {
      payload["name"] = name;
    }

    if (inStock !== category.is_live) {
      payload["is_live"] = inStock;
    }

    axios({
      method: "PATCH",
      url: `${DOMAIN}/menus/item-categories/`,
      headers: getAuthorizationHeader(),
      params: {
        uid: restaurant.uid,
        id: category.id,
      },
      data: payload,
    })
      .then((res) => {
        dispatch(
          setMenuEditor({
            ...menuEditor,
            saving: false,
          })
        );
        dispatch(
          setItemCategories(
            itemCategories.map((ic) => {
              if (ic.id === res.data.id) {
                return {
                  ...ic,
                  ...res.data,
                };
              }
              return ic;
            })
          )
        );
        dispatch(
          setNotify({
            open: true,
            action: "Changes Saved!",
            severity: "success",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      })
      .catch((err) => {
        dispatch(
          setMenuEditor({
            ...menuEditor,
            saving: false,
          })
        );
        let errorMessage = "Something went Wrong!";
        try {
          errorMessage = JSON.parse(err.request.response).message;
        } catch (error) {}
        dispatch(
          setNotify({
            open: true,
            action: errorMessage,
            severity: "danger",
            autoHideDuration: 3000,
            vertical: "bottom",
            horizontal: "right",
          })
        );
      });
  };

  const setCurrentlyEditingCategory = (item) => {
    dispatch(
      setMenuEditor({
        currentlyEditing: "item",
        entryId: item.id,
        saving: false,
      })
    );
  };

  const resetState = () => {
    const categry = getCategory();

    setName(categry.name);
    setInStock(categry.is_live);
    dispatch(
      setMenuEditor({
        ...menuEditor,
        saving: false,
      })
    );
  };

  return (
    <EditorWrapper>
      <form onSubmit={handleSave}>
        <Stack spacing={3}>
          {/* Header */}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography level="title-lg" fontWeight={700}>
              {name || "Item Category"}
            </Typography>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Button
                variant="solid"
                color="neutral"
                size="sm"
                startDecorator={<Save />}
                disabled={menuEditor.saving}
                type="submit"
              >
                {menuEditor.saving ? "Saving" : "Save"}
              </Button>
              <MoreSettings resetState={resetState} category={getCategory()} />
            </Stack>
          </Stack>
          {/* Basic Details */}
          <Stack spacing={2}>
            <Typography level="body-md">Basic Details</Typography>
            <Grid container>
              <Grid xs={6}>
                <FormControl>
                  <FormLabel>Name</FormLabel>
                  <Input
                    required
                    size="sm"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid xs={6}>
                <Stack direction="row" justifyContent="flex-end">
                  <FormControl>
                    <FormLabel>In Stock</FormLabel>
                    <Switch
                      size="sm"
                      color="neutral"
                      checked={inStock}
                      variant={inStock ? "solid" : "outlined"}
                      onChange={() => setInStock(!inStock)}
                    />
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
          {/* Items */}
          <Stack spacing={2}>
            <Typography level="body-md">Items</Typography>
            <Stack spacing={2}>
              {getCategoryItems().length ? (
                getCategoryItems().map((item, index) => (
                  <Stack
                    key={index}
                    px={1.5}
                    py={0.75}
                    bgcolor="#FAFAFA"
                    spacing={0.5}
                    borderRadius="6px"
                    border="1px solid #DDDDDD"
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Stack direction="row" alignItems="center" spacing={0.75}>
                        <Tooltip title="Coming soon" size="sm">
                          <MenuIcon
                            fontSize="small"
                            sx={{
                              cursor: "grab",
                              color: "grey",
                            }}
                          />
                        </Tooltip>
                        <Circle
                          fontSize="small"
                          sx={{ color: item.is_veg ? "#00B620" : "#F90202" }}
                        />
                        <Typography
                          level="title-md"
                          fontWeight={700}
                          onClick={() => setCurrentlyEditingCategory(item)}
                          sx={{
                            cursor: "pointer",
                          }}
                        >
                          {item.name}
                        </Typography>
                        <IconButton
                          size="sm"
                          onClick={() => setCurrentlyEditingCategory(item)}
                        >
                          <DriveFileRenameOutline />
                        </IconButton>
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1}
                      >
                        <Typography level="body-sm">Variants:</Typography>
                        <Typography level="title-sm">
                          <b>{item.variants.length}</b>
                        </Typography>
                      </Stack>
                    </Stack>
                    <Grid container>
                      <Grid xs={12}>
                        <Typography level="body-xs" textColor="neutral.400">
                          {item.description.length > 130 ? (
                            <>
                              {item.description.slice(0, 130)}{" "}
                              <b>read more..</b>
                            </>
                          ) : (
                            item.description
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Stack>
                ))
              ) : (
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  minHeight="200px"
                >
                  <Typography level="body-xs">No Items Linked</Typography>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      </form>
    </EditorWrapper>
  );
}

import { Link } from "react-router-dom";

import { Breadcrumbs, Stack, Typography } from "@mui/joy";

import urls from "../../../../../utils/urls.json";
import { useSelector } from "react-redux";

export default function SettingHeader({ title, action }) {
  const { restaurant } = useSelector((state) => state.restaurant);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      pb={2}
    >
      <Breadcrumbs
        sx={{
          px: 0,
        }}
      >
        <Link
          to={`/${restaurant.uid}/${urls.restaurant.children.settings.path}`}
        >
          <Typography level="title-md">Settings</Typography>
        </Link>
        <Typography level="body-md">{title}</Typography>
      </Breadcrumbs>
      {action}
    </Stack>
  );
}

import { useSelector } from "react-redux";

import { PDFDownloadLink } from "@react-pdf/renderer";
import MenuQR from "./menuQR";

export default function DownloadQR({ table, children }) {
  const { restaurant } = useSelector((state) => state.restaurant);

  return (
    <PDFDownloadLink
      document={<MenuQR restaurant={restaurant} table={table} />}
      fileName={table ? `qr-${table.name}.pdf` : "menu-qr.pdf"}
    >
      {children}
    </PDFDownloadLink>
  );
}

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from "@mui/joy";
import TableOrderItem from "./tableOrderItem";

export default function TableOrder({ order, seqNo }) {
  return (
    <Accordion>
      <AccordionSummary>
        <Typography level="body-sm" fontWeight={700}>
          Order #{seqNo}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={1}>
          {order.order_items.map((orderItem, index) => (
            <TableOrderItem order={order} orderItem={orderItem} key={index} />
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
